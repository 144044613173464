import React from 'react';

export default function ProfileCard({ data }) {

    return (
        <div 
            className="flex items-center justify-between mb-4 cursor-pointer" 
        >
            <div className="flex items-center gap-4">
                <img 
                    className="h-12 w-12 rounded-full border border-custom-f9a826 bg-custom-e3dEd8 object-cover" 
                    src={data?.image?.image_480?.publicURL} 
                    alt={data?.name} 
                />
                <div>
                    <div className="text-sm font-semibold text-gray-800">{data?.name}</div>
                        {!data?.badge ? null :
                            <div className="flex items-center gap-1 mb-1">
                                <img className='h-5 w-5' src={`/icons/generalIcons/achieverStar.svg`} alt='badge'/>
                                <p className='text-sm text-primary'>{data?.badge}</p>
                            </div>
                        }
                </div>
            </div>
        </div>
    );
}
