import React, { useState } from 'react';
import Button from '../main/Button';
import { useNavigate } from 'react-router-dom';
import api from '../../api/axiosInstance';
import { setToken } from '../../utils/secureStorage';
import Snackbar from '../main/elements/Snackbar';

function Verification({ email, setEmail }) {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const [resendLoading, setResendLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({});
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);

    const resend = async () => {
        setResendLoading(true);
        const response = await api.post('/signup/verify/resend', { email });
        console.log(response.data.message);
        if (response.data.error) {
            setError({ general: response.data.message });
            setShowSnackbar(true);
            setSnackbarMessage(response.data.message);
            setResendLoading(false);

        }
        setShowSnackbar(true);
        setSnackbarMessage("A new OTP has been resent");
        setResendLoading(false);
    };

    const verify = async () => {
        setIsLoading(true)
        try {
            const response = await api.post('/signup/verify/otp', { email, otp });
            if (response.data.error) {
                setError({ general: response.data.message });
                setShowSnackbar(true);
                setSnackbarMessage(response.data.message);
            } else {
                setToken('accessToken', response.data.accessToken);
                setToken('refreshToken', response.data.refreshToken);
                navigate('/roleselect');
            }
        } catch (err) {
            setError({ general: 'Login failed. Please try again.' });
            setShowSnackbar(true);
            setSnackbarMessage(error.general);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='h-screen relative overflow-hidden w-full'>
            {showSnackbar && (
                <Snackbar
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
                />
            )}
            <div className="flex flex-col items-center justify-center bg-primary">
                <div className="relative">
                    <h1 className="absolute text-white text-center text-3xl font-bold" style={{ left: '50%', top: '35%', transform: 'translate(-50%, -50%)' }}>Engage</h1>
                    <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/elipse.svg`} alt="ElipseIcon" />
                </div>
                <div className="absolute bottom-0 w-full bg-white p-8 rounded-t-2xl">
                    <div className="text-center">
                        <p className="text-2xl text-center font-semibold font-poppins text-primary">Verify your email</p>
                        <p className="text-center text-sm font-lato text-gray-500 mt-1">
                            Enter the code we have sent you by email at
                            <span className="font-bold"> {email}</span>
                        </p>
                    </div>
                    <div className="mt-5">
                        <input
                            type="text"
                            placeholder="Enter Code"
                            className="border border-gray-300 rounded-full w-full py-2 px-4 text-black"
                            onChange={(e) => setOtp(e.target.value)}
                        />
                    </div>
                    <div className="flex justify-between mt-5 gap-x-4">
                        <Button text="Resend" isLoading={resendLoading} onClick={resend} variant="default" />
                        <Button text="Continue" isLoading={isLoading} onClick={verify} variant="primary" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Verification;
