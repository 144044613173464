import React, { useEffect, useState } from 'react';
import { removeToken } from '../../../utils/secureStorage';
import { useNavigate } from 'react-router-dom';
import Header from '../../main/Header';
import useCachedFetch from '../../../hooks/useCachedFetch';
import InputText from '../../main/InputText';
import Button from '../../main/Button';
import api from '../../../api/axiosInstance';
import Snackbar from '../../main/elements/Snackbar';

const Settings = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [value, setValue] = useState("");
  const [errors, setErrors] = useState({});
  const [deleteMethod, setDeleteMethod] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (deleteModalVisible) {
      const modal = document.querySelector('.otp-modal');
      if (modal) {
        setTimeout(() => {
          modal.classList.remove('translate-y-full');
          modal.classList.add('translate-y-0');
        }, 10); 
      }
    }
  }, [deleteModalVisible]);
  
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const performLogout = () => {
    removeToken('accessToken');
    removeToken('refreshToken');
    navigate('/login');
  };

  const { data: profile, loading: profileLoading, error: error, refetch: refetchProfile } = useCachedFetch('profile', '/profile');

  const deleteModalOpen = () => {
    setDeleteModalVisible(true);
  };

  const handleCloseModal = () => {
    setDeleteModalVisible(false);
  };

  async function processDelete() {
    try {
      setIsLoading(true)
      deleteModalOpen()
      const result = await api.get('/login/delete');
      console.log(result);
      if (result.data.error) {
          setShowSnackbar(true);
          setSnackbarMessage(result.data.message);
          handleCloseModal();
          setIsLoading(false)
          return
      }
      setDeleteMethod(result.data.method);
    } catch (err) {
        setShowSnackbar(true);
        setSnackbarMessage("Error occurred while deleting account");
        setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  async function deleteAccountConfirm(event) {
    setIsLoading(true)
    event.preventDefault();
    const data = deleteMethod === 'password' ? { password: value } : { otp: value };
    try {
      const result = await api.delete(`/login/delete/${deleteMethod === "password" ? "password" : "otp"}`, { data });
      console.log(result.data)
      if (result.data.error) {
          setErrors(result.data.error);
          setShowSnackbar(true);
          setSnackbarMessage(result.data.message);
          setIsLoading(false)
          return;
        }
      performLogout();
    } catch (err) {
        setShowSnackbar(true);
        setSnackbarMessage("Error occurred while deleting account");
        setIsLoading(false)
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="relative h-screen max-w-md bg-custom-fcfff7">
      <Header isBack={true} text={"Settings"} />
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          onDismiss={() => setShowSnackbar(false)}
        />
      )}
      <div className="p-4">
        <div className="relative flex items-center p-4 bg-primary rounded-lg gap-4 overflow-hidden" onClick={() => navigate('./editprofile')}>
          <div className="relative z-20">
            {profileLoading ? ( 
                <div role="status" className="w-full max-w-md animate-pulse bg-white rounded-full overflow-hidden">
                  <div className="w-20 h-20 bg-gray-200 dark:bg-gray-800" />
                </div>
              ) : (
                <img
                src={profile.profile.image.image_480.publicURL}
                alt="Profile"
                className="w-20 h-20 rounded-full border-4 border-custom-f9a826 object-cover"
              />
            )}
          </div>
          <div className="flex flex-col gap-1">
            {profileLoading ? ( 
              <div role="status" className="w-full max-w-md animate-pulse">
                <div className="w-28 h-5 bg-gray-200 rounded-md dark:bg-gray-800" />
              </div>
            ) : (
              <span className="text-white text-lg font-medium">{profile.profile.name}</span>
            )}
            <div className="flex items-center gap-2">
              {profileLoading ? ( 
                <div role="status" className="w-full max-w-md animate-pulse">
                  <div className="w-28 h-5 bg-gray-200 rounded-md dark:bg-gray-800" />
                </div>
              ) : (
                <div className="h-6 flex flex-row items-center bg-white rounded-full px-2 py-1">
                  <img src={`/icons/postIcons/newComerIcon.svg`} className='h-4 w-4' alt="dsffg" />
                  <span className="text-primary text-sm pl-2">{profile.profile.badge}</span>
                </div>
              )}
            </div>
          </div>
          <img src={`/icons/generalIcons/greetingBarLineTop.svg`} className="absolute top-0 right-0 " alt="top" />
          <img src={`/icons/generalIcons/greetingBarLine.svg`} className="absolute top-3 -left-10 transform rotate-180 " alt="" />
        </div>

        <div className="mt-4">
          <h3 className="font-sans font-semibold text-sm">Security</h3>
          <div className="flex flex-col gap-y-4 mt-2 mx-px">
            <button className="flex justify-between items-center p-2 px-4 border border-custom-d9d9d9 rounded-full" onClick={() => navigate('./changeemail')}>
              <span className="text-tertiary text-sm">Change Email</span>
              <img src={`/icons/generalIcons/carretRight.svg`} alt="" />
            </button>
            <button className="flex justify-between items-center p-2 px-4 border border-custom-d9d9d9 rounded-full" onClick={() => navigate('./changepassword')}>
              <span className="text-tertiary text-sm">Change Password</span>
              <img src={`/icons/generalIcons/carretRight.svg`} alt="" />
            </button>
            <button className="flex justify-between items-center p-2 px-4 border border-custom-d9d9d9 rounded-full" onClick={() => navigate('./editprofile')}>
              <span className="text-tertiary text-sm">Edit Profile</span>
              <img src={`/icons/generalIcons/carretRight.svg`} alt="" />
            </button>
          </div>
        </div>

        <div className="mt-4">
          <h3 className="font-sans font-semibold text-sm">Privacy & Terms</h3>
          <div className="flex flex-col gap-y-4 mt-2 mx-px">
            <button className="flex justify-between items-center p-2 px-4 border border-custom-d9d9d9 rounded-full" onClick={() => navigate('./privacypolicy')}>
              <span className="text-tertiary text-sm">Privacy Policy</span>
              <img src={`/icons/generalIcons/carretRight.svg`} alt="" />
            </button>
            <button className="flex justify-between items-center p-2 px-4 border border-custom-d9d9d9 rounded-full" onClick={() => navigate('./termsconditions')}>
              <span className="text-tertiary text-sm">Terms & Condition</span>
              <img src={`/icons/generalIcons/carretRight.svg`} alt="" />
            </button>
          </div>
        </div>

        <div className="mt-4">
          <h3 className="font-sans font-semibold text-sm">Account</h3>
          <div className="flex flex-col gap-y-4 mt-2 mx-px">
            <button className="flex justify-between items-center p-2 px-4 border border-custom-d9d9d9 rounded-full " onClick={processDelete} >
              <span className="text-custom-ea1111 text-sm">Delete Account</span>
              <img src={`/icons/generalIcons/carretRight.svg`} alt="" />
            </button>
            <button className="flex justify-between items-center p-2 px-4 border border-custom-d9d9d9 rounded-full" onClick={toggleModal} type="button">
              <span className="text-tertiary text-sm">Logout</span>
              <img src={`/icons/generalIcons/carretRight.svg`} alt="" />
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
          <div id="popup-modal" tabIndex="-1" className="fixed inset-0 z-50 flex justify-center items-center w-full h-full overflow-y-auto overflow-x-hidden">
            <div className="relative p-4 w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
                <div className="p-4 md:p-5 text-center">
                  <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to log out?</h3>
                  <button onClick={performLogout} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    Yes, I'm sure
                  </button>
                  <button onClick={toggleModal} type="button" className="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {deleteModalVisible && (
        <div className="fixed inset-0 flex items-end justify-center bg-gray-800 bg-opacity-50">
          <div className="w-full max-w-md p-6 pb-10 bg-white rounded-t-xl shadow-lg transform transition-transform duration-300 ease-out otp-modal translate-y-full">
            <div className="relative flex justify-center mb-2">
              <h2 className="text-2xl font-semibold text-center text-custom-ea1111 pb-2">Delete Account</h2>
              <button onClick={handleCloseModal} className="absolute font-medium text-custom-7788f4 right-1">
                Cancel
              </button>
            </div>
            <p className="w-full px-10 mb-5 text-center text-custom-404040 pb-4">
              Enter your {deleteMethod === "email" ? "otp" : "password"} to continue
            </p>
            <form>
              <div className="mb-10">
                <InputText placeholder={`Enter ${deleteMethod === "email" ? "otp" : "password"}`} onChange={(event) => setValue(event.target.value)} hasError={errors.value}/>
              </div>
              <Button variant={"primary"} text={"Continue"} isLoading={isLoading} onClick={(event) => deleteAccountConfirm(event)} type={"submit"} buttonClass={"!bg-custom-ea1111"}/>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings
