import React, { useState } from 'react';
import Header from '../../main/Header';
import api from '../../../api/axiosInstance';

const BeAMentor = () => {
    const [isInterested, setIsInterested] = useState(false);

    async function stateChange() {
        const mentorshipProgramme = !isInterested;
        try {
            const result = await api.post('/mentorshipprogramme/', {mentorshipProgramme});
            if (!result.data.error) {
                console.log(result.data.message);
            }
        } catch (error) {
            console.error("Error updating state:", error);
        }
        finally {
            setIsInterested(mentorshipProgramme);
            console.log("State changed to:", mentorshipProgramme);
        }
    };

    return (
        <div className='h-screen relative overflow-y-auto overflow-hidden w-full'>
            <Header isBack={true} text={"Be A Mentor"} />
            <div className="flex flex-col items-center">
                <div className="w-full aspect-w-16 aspect-h-9 py-2.5">
                    <img
                        className="w-full h-full object-cover"
                        src={`/images/public/mentorshipProgram.png`}
                        alt="Mentorship Program"
                    />
                </div>
                <div className="px-5 space-y-3">
                    <p className="text-sm font-semibold text-tertiary">
                        Dear Alumni,
                    </p>
                    <p className="text-sm font-lato font-medium text-tertiary">
                        You've already experienced the transformative power of education firsthand. Now, we invite you to pay it forward by becoming a mentor in our mentorship program. Through volunteering your time and expertise, you can make a lasting impact on the lives of current students, guiding them towards excellence and helping them achieve their goals.
                    </p>
                    <p className="text-sm font-lato font-medium text-tertiary">
                        Show your interest in nurturing the next generation of leaders and innovators, embrace the spirit of mentorship, and help shape the future of our students, embodying our shared commitment to 'Transforming Education Transforming India.'
                    </p>
                </div>
                <div className="flex justify-center w-full items-center pt-10 px-3">
                    <button
                        className={`w-full md:w-46 flex items-center justify-center h-12 rounded-full ${isInterested ? 'bg-primary text-white' : 'bg-gray-300 text-tertiary'}`}
                        onClick={stateChange}
                    >
                        {isInterested ? 'Unmark Interest' : 'Show Interest'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BeAMentor;