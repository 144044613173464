import React, { useState } from 'react';
import Header from '../../main/Header';
import api from '../../../api/axiosInstance';

const AidForAspiring = () => {
    const [openCard, setOpenCard] = useState('angelDonor');
    const [isInterested, setIsInterested] = useState(false);

    const handleSelectedOption = (option) => {
        if (openCard !== option) {
            setOpenCard(option);
        }
    };

    async function stateChange() {
        const aidForAspiringProgramme = !isInterested;
        const type = openCard;
        console.log(type);
        try {
            const result = await api.post('/aidforaspiring/', {aidForAspiringProgramme, type});
            if (!result.data.error) {
                console.log(result.data.message);
            }
        } catch (error) {
            console.error("Error updating state:", error);
        }
        finally {
            setIsInterested(aidForAspiringProgramme);
            console.log("State changed to:", aidForAspiringProgramme);
        }
    };

    return (
        <div className='h-screen relative overflow-y-auto overflow-hidden w-full '>
            <Header isBack={true} text={"Aid For Aspiring"}/>
            <div className="flex flex-col items-center">
                    <div className="w-full aspect-w-16 aspect-h-9 py-2.5">
                        <img 
                            className="w-full h-full object-cover" 
                            src={`/images/public/aidForAspiring.png`} 
                            alt="Aid for Aspiring" 
                        />
                    </div>
                    <div className="px-5 space-y-3">
                        <p className="text-sm font-medium font-lato text-tertiary">
                            Welcome to our Aid for Aspiring program, where we have the privilege of making a difference in the lives of students seeking financial assistance. Through this initiative, you can choose to financially support a student's education by selecting one of three categories.
                        </p>
                        <p className="text-sm font-medium font-lato text-tertiary">
                            Our collective efforts have the power to transform lives and pave the way for a brighter future for aspiring individuals. Together, we can enhance accessibility, inclusivity, and empowerment within education. Let's embark on this journey of impact and change as a unified force.
                        </p>
                        <p className="font-bold text-base font-lato text-tertiary">
                            You can donate among the following categories.
                        </p>
                    </div>
                </div>
                <div className='px-5 pt-4'>
                    <div className="border border-custom-d9d9d9 rounded-xl overflow-hidden">
                        <div
                            className={`cursor-pointer h-12 p-4 flex justify-between items-center ${openCard === 'angelDonor' ? 'bg-primary text-white' : 'bg-white border-b border-custom-d9d9d9'}`}
                            onClick={() => handleSelectedOption('angelDonor')}>
                            <p className={`font-poppins ${openCard === 'angelDonor' ? 'font-bold' : 'font-bold text-tertiary'}`}>
                                Angel Donor
                            </p>
                            {openCard === 'angelDonor' && (
                                <img src={`/images/public/tick.png`} className="h-9 w-9" alt="Selected" />
                            )}
                        </div>
                        {openCard === 'angelDonor' && (
                            <div className="p-3 border-b border-custom-d9d9d9">
                                <p className="text-sm text-tertiary">
                                    Provide comprehensive support by covering both tuition and residential fees, ensuring that the student has access to a supportive living environment conducive to their studies.
                                </p>
                            </div>
                        )}

                        <div
                            className={`cursor-pointer h-12 p-4 flex justify-between items-center ${openCard === 'pioneerDonor' ? 'bg-primary text-white' : 'bg-white border-b border-custom-d9d9d9'}`}
                            onClick={() => handleSelectedOption('pioneerDonor')}>
                            <p className={`font-poppins ${openCard === 'pioneerDonor' ? 'font-bold' : 'font-bold text-tertiary'}`}>
                                Pioneer Donor
                            </p>
                            {openCard === 'pioneerDonor' && (
                                <img src={`/images/public/tick.png`} className="h-9 w-9" alt="Selected" />
                            )}
                        </div>
                        {openCard === 'pioneerDonor' && (
                            <div className="p-3 border-b border-custom-d9d9d9">
                                <p className="text-sm text-tertiary">
                                    You have the opportunity to cover the entire cost of tuition for a student, empowering them to access quality education without financial constraints.
                                </p>
                            </div>
                        )}

                        <div
                            className={`cursor-pointer h-12 p-4 flex justify-between items-center ${openCard === 'communityDonor' ? 'bg-primary text-white' : 'bg-white'} `}
                            onClick={() => handleSelectedOption('communityDonor')}>
                            <p className={`font-poppins ${openCard === 'communityDonor' ? 'font-semibold' : 'font-bold text-tertiary'}`}>
                                Community Donor
                            </p>
                            {openCard === 'communityDonor' && (
                                <img src={`/images/public/tick.png`} className="h-9 w-9" alt="Selected" />
                            )}
                        </div>
                        {openCard === 'communityDonor' && (
                            <div className="p-3 rounded-b-xl">
                                <p className="text-sm text-tertiary">
                                    You can contribute towards a portion of the student's tuition fees, easing their financial burden and helping them pursue their dreams.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-center w-full items-center pt-5 px-4 mb-6">
                    <button
                        className={`w-full md:w-46 flex items-center justify-center h-12 rounded-full ${isInterested ? 'bg-primary text-white' : 'bg-gray-300 text-tertiary'}`}
                        onClick={stateChange}
                    >
                        {isInterested ? 'Unmark Interest' : 'Show Interest'}
                    </button>
                </div>
        </div>
    )
};

export default AidForAspiring;
