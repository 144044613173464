import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../api/axiosInstance';
import { getToken, setToken } from '../../utils/secureStorage';
import InputText from '../main/InputText';
import Button from '../main/Button';
import Snackbar from '../main/elements/Snackbar';

const Login = ({ email, setEmail }) => {
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  
  useEffect(() => {
    const refreshToken = getToken('refreshToken');
    if (refreshToken) {
      navigate('/app');
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await api.post('/login', { email, password });
      if (response.data.error) {
        if(response.data.message === "Account not verified") {
          navigate(`/verification`, {email: email});
        }
        setShowSnackbar(true);
        setError(response.data.message);
        setSnackbarMessage(response.data.message);
      } else {
        setToken('accessToken', response.data.accessToken);
        setToken('refreshToken', response.data.refreshToken);
        navigate('/app');
      }
    } catch (err) {
      setError('Login failed. Please try again.');
      setShowSnackbar(true)
      setSnackbarMessage(error)
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignInWithGoogle = async () => {
    try {
      window.open('https://engageapi.webhizzy.in/login/google', '_self');
    } catch (error) {
      console.error('Google sign-in error:', error);
    }
  };

  return (
    <div className='h-screen relative overflow-hidden w-full'>
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          onDismiss={() => setShowSnackbar(false)}
        />
      )}
      <div className="flex flex-col items-center justify-center bg-primary">
        <div className="relative">
          <h1 className="absolute text-white text-center text-3xl font-bold" style={{ left: '50%', top: '35%', transform: 'translate(-50%, -50%)' }}>Engage</h1>
          <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/elipse.svg`} alt="ElipseIcon" />
        </div>
        <div className="absolute bottom-0 w-full bg-white py-5 px-5 rounded-t-2xl">
          <div>
            <h2 className="text-2xl text-center font-semibold font-poppins text-primary">Welcome Back!</h2>
            <p className="text-center text-sm font-lato text-gray-500 mt-1">Enter your details to Sign In</p>
          </div>
          <div className="mt-6 space-y-4">
            <InputText placeholder="Enter Email Address" required type={"email"} onChange={(e) => setEmail(e.target.value)} hasError={error.email} />
            <InputText placeholder="Enter Password" required type={showPassword ? "password" : "text"} onChange={(e) => setPassword(e.target.value)} hasError={error.password}>
              <button onClick={() => setShowPassword(!showPassword)} className="absolute right-4 top-0 h-full flex items-center z-10">
                <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/eye.svg`} alt="Eye Icon" />
              </button>
            </InputText>
          </div>
          <Link className='flex justify-end mt-4' to={"/forgotpassword"}>
            <p className='text-sm text-primary font-medium'>Forgot password?</p>
          </Link>
          <div className="mt-4">
            <Button text="Continue" isLoading={isLoading} onClick={handleSubmit} variant="primary" />
          </div>
          <button className="flex justify-center items-center w-full border mt-4 gap-x-3 border-gray-300 rounded-full px-4 py-2" onClick={handleSignInWithGoogle}>
            <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/google.svg`} alt="Google Icon" />
            <span className='text-gray-700 font-lato text-base font-medium'>Google</span>
          </button>
          <Link to={'/signup'} className='mt-5 text-sm flex gap-x-1 justify-center items-center'>
            <p className='text-gray-700 text-sm'>Dont have an account?</p>
            <p className="text-primary font-bold" >Sign Up</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
