import React, { useState, useEffect } from 'react';

const InstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const ios = /iphone|ipad|ipod/.test(userAgent);
    const standalone = ('standalone' in window.navigator) && (window.navigator.standalone);

    setIsIos(ios);
    setIsInStandaloneMode(standalone);

    if (!ios) {
      const handleBeforeInstallPrompt = (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        setDeferredPrompt(e);
        // Update UI notify the user they can install the PWA
        setShowInstallPrompt(true);
      };

      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    } else {
      setShowInstallPrompt(true);
    }
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      // We've used the prompt, and can't use it again, throw it away
      setDeferredPrompt(null);
      setShowInstallPrompt(false);
    }
  };

  if (!showInstallPrompt || isInStandaloneMode) {
    return null;
  }

  return (
    <div className='z-50 fixed bottom-4 left-0 w-full max-w-md'>
      <div className='mx-4 bg-white border border-2 rounded-lg grid p-4'>
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Install app for a exact experience.</h5>
        <p className="font-medium text-gray-700 dark:text-gray-400 mb-3">Made for mobile phones.</p>
        <p className="font-normal text-gray-700 dark:text-gray-400 mb-3">API might take upto 10 minutes to start as it goes to hibernation to save cost. We are under development.</p>

        {!isIos ? (
          <button onClick={handleInstallClick} type="button" className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Install</button>
        ) : (
          <div>
            <h3 class="mb-2 text-lg font-semibold text-green-900 dark:text-white">To install this app on your <b>iPhone</b> <small>(in Safari)</small></h3>
            <ol class="max-w-md space-y-1 text-green-500 list-decimal list-inside dark:text-gray-400">
              <li>
                Tap the <span class="font-semibold text-green-900 dark:text-white">Share</span> button.
              </li>
              <li>
                Select<span class="font-semibold text-green-900 dark:text-white"> Add to Home Screen</span>
              </li>
            </ol>
          </div>
        )}
      </div>
    </div>
  );
};

export default InstallPrompt;
