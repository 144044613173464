import { React, useState } from "react";
import Button from "../../main/Button";
import InputText from "../../main/InputText";
import { useNavigate } from 'react-router-dom';
import Header from "../../main/Header";
import api from '../../../api/axiosInstance';
import Snackbar from '../../main/elements/Snackbar';
import useCachedFetch from "../../../hooks/useCachedFetch";

const SubmitEntry = (e) => {
    const [isloading, setIsLoading] = useState(false);
    const [error, setError] = useState({});
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [dialogbox, setDialogbox] = useState(true);
    const navigate = useNavigate();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const { refetch: refetchStatus } = useCachedFetch('status', '/cheerBuddy');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = async () => {
        setIsModalOpen(!isModalOpen);
        setIsLoading(false);
        navigate('/app/cheerbuddy/status');
    };

    const submit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            const response = await api.post('/cheerBuddy', { url, description });
            if (response.data.error) {
                setError(response.data.message);
                setShowSnackbar(true);
                setSnackbarMessage(response.data.message);
                setIsLoading(false);
            } else {
                refetchStatus();
                setIsModalOpen(true);
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error.message);
            setShowSnackbar(true);
            setSnackbarMessage(error.message);
        }
    };

    return (
        <div className="h-full bg-custom-fcfff7">
            <Header isBack={true} text={"Submit Entry"} />
            {showSnackbar && (
                <Snackbar
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
                />
            )}
            <form onSubmit={submit}>
                <div className="px-5">
                    <div className="flex mt-4 relative">
                        <div className="absolute z-10 left-2 top-0 h-full flex items-center justify-center">

                        </div>
                        <InputText
                            placeholder="Enter Url"
                            onChange={(e) => setUrl(e.target.value)}
                            hasError={error.url}
                            extraStyles="!pl-12"
                            required={true}
                        >
                            <svg className="absolute z-40 top-2 left-3" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d9d9d9"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" /></svg>
                        </InputText>

                    </div>
                    {/* dialog box8 */}
                    {dialogbox &&
                        <div className="relative bg-custom-ececec rounded-xl p-5 mt-6">
                            <div className="absolute top-[-14px] left-[50px] w-[25px] h-[25px] bg-custom-ececec transform rotate-45 " />
                            <button className="absolute right-4 top-4" onClick={() => { setDialogbox(false) }}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 15L1 1M15 1L1 15" stroke="#595959" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                            <p className="text-[14px] font-bold font-Lato z-10">Enter Link of your post</p>
                            <p className="text-[12px] text-custom-444341 font-Lato pt-1 z-10">
                                Submit the public URL of your post so that we can verify it and provide you with rewards.
                            </p>
                        </div>}
                    {/* dialog box8 */}
                    <div className="mt-4">
                        <textarea
                            className="border w-full h-28 rounded-xl text-black px-5 pt-2.5 border-gray-300 focus:border-primary"
                            placeholder={"Description (optional)"}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex items-center w-full bottom-0 p-5">
                    <Button
                        text={"Submit"}
                        isLoading={isloading}
                        onPress={submit}
                        variant={"primary"}
                        buttonClass="w-full"
                    />
                </div>
            </form>
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div
                        id="successModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        className="relative p-4 w-full max-w-md"
                    >
                        {/* Modal content */}
                        <div className="relative p-4 text-center bg-gray-200 rounded-lg shadow-xl dark:bg-gray-800 sm:p-5">
                            <div className="h-28 w-full flex items-center justify-center">
                                <img src={`/images/public/animation/tickGif.gif`} alt='tick GIF'/>
                            </div>
                            <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                                Successfully submitted entry.
                            </p>
                            <button
                                onClick={toggleModal}
                                type="button"
                                className="py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-teal-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-900"
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default SubmitEntry;