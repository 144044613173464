import React, { createContext, useState, useContext } from 'react';

const ApiContext = createContext();

export const useApiContext = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  const setApiData = (key, data) => {
    setCache((prevCache) => ({ ...prevCache, [key]: data }));
  };

  const getApiData = (key) => cache[key];

  return (
    <ApiContext.Provider value={{ setApiData, getApiData }}>
      {children}
    </ApiContext.Provider>
  );
};
