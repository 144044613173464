export const decodeToken = (token) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload;
    } catch (error) {
      console.error('Invalid token', error);
      return null;
    }
  };
  
  export const isTokenExpired = (token) => {
    const payload = decodeToken(token);
    if (!payload) {
      return true;
    }
    const currentTime = Date.now() / 1000;
    return payload.exp < currentTime;
  };
  