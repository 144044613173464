import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CBShareCard from '../../main/elements/CBShareCard';

const CheerBuddy = ({ setUrl, setCaption, setShowModal }) => {
    const postData = [
        {
            "title": "LPU student from Department of Student Research and Project, Student Welfare Wing, Nishant Verma has outskilled everyone at the India Skills State Level Competition 2024, Hyderabad by emerging as the Winner in the ‘Autonomous Mobile Robotics’ Category!",
            "url": "https://www.instagram.com/p/C8rLvbgBHA1/?utm_source=ig_web_button_share_sheet",
            "image": "/temp/img1.jpg",
            "id": "lpuuniversity",
            "caption": ""
        },
        {
            "title": "Relish this soothing click of our #BeautifulLPUCampus and tag a friend who makes #LifeAtLPU an even more memorable experience for you!",
            "url": "https://www.instagram.com/p/C8ql5ZcBti4/?utm_source=ig_web_button_share_sheet",
            "image": "/temp/img2.jpg",
            "id": "lpuuniversity",
            "caption": ""
        },
        {
            "title": "POV: Experiencing bliss at the #BeautifulLPUCampus is what our #LPUFamily embraces each day. Tell us about your favourite corner at our campus and why you love it the most!",
            "url": "https://www.instagram.com/reel/C8o5kZQvlBK/?utm_source=ig_web_button_share_sheet",
            "image": "/temp/img3.jpg",
            "id": "lpuuniversity",
            "caption": ""
        }
    ];

    const [dialogbox, setDialogbox] = useState(true);
    const navigate = useNavigate();

    // const handleRedirect = (postUrl, caption) => {
    //   const url = `https://www.instagram.com/create/select/?media=${encodeURIComponent(postUrl)}&caption=${encodeURIComponent(caption)}`;
    //   window.location.href = url;
    // };

    // if (balanceLoading) {
    //   return <div>Loading...</div>;
    // }

    return (
        <div className='h-screen relative overflow-y-auto overflow-hidden w-full'>
            <div className='absolute top-0 right-0 w-full z-10 p-4 bg-white shadow-md'>
                <div className='flex justify-end h-6'>
                    <button onClick={() => { navigate('/app/profile') }}>
                        <img src={`/icons/navigationBar/profileActive.svg`} className='h-6' alt="Profile" />
                    </button>
                </div>
            </div>
            <div className='h-screen relative overflow-y-auto overflow-x-hidden no-scrollbar w-full'>
                <div className='h-screen bg-custom-fcfff7 px-6 pt-20'>
                    <div className='h-48 w-full rounded-lg border overflow-hidden shadow-md'>
                        <img src={`/images/public/homeCb.png`} className='h-full w-full object-cover' alt='cb home'/>
                    </div>
                    <div className='my-8 flex flex-row justify-evenly '>
                        <div className='items-center'>
                            <button className='h-14 w-32 bg-white rounded-full flex active:bg-gray-200 items-center justify-center' onClick={() => { navigate('/app/cheerbuddy/submitentry') }} >
                                <svg className='h-5' viewBox="0 0 89 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_3373_3539)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V19C0 21.7614 2.23858 24 5 24H19C21.7614 24 24 21.7614 24 19V5C24 2.23858 21.7614 0 19 0H5ZM8 5C6.34315 5 5 6.34315 5 8V16C5 17.6569 6.34315 19 8 19H16C17.6569 19 19 17.6569 19 16V8C19 6.34315 17.6569 5 16 5H8Z" fill="#F68220" />
                                        <rect x="28.7998" y="0.599976" width="17.4" height="4.8" rx="2.4" fill="#F68220" />
                                        <rect x="28.7998" y="9.29993" width="60.6" height="4.8" rx="2.4" fill="#F68220" />
                                        <rect x="28.7998" y="18.5997" width="46.8" height="4.8" rx="2.4" fill="#F68220" />
                                    </g>
                                </svg>
                            </button>
                            <p className='text-tertiary text-center font-poppins text-sm'>
                                Submit entry
                            </p>
                        </div>
                        <div className='items-center'>
                            <button className='h-14 w-14 bg-white rounded-full flex active:bg-gray-200 items-center justify-center' onClick={() => { navigate('/app/cheerbuddy/status') }} >
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-[37px] h-[37px] text-primary" viewBox="0 -960 960 960" fill="currentColor"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-66.67q139.58 0 236.46-96.87 96.87-96.88 96.87-236.46H480.18v-333.33q-139.51 0-236.51 96.87-97 96.88-97 236.46t96.87 236.46q96.88 96.87 236.46 96.87Z" /></svg>
                            </button>
                            <p className='text-tertiary font-poppins text-sm text-center'>
                                Status
                            </p>
                        </div>
                        <div className='items-center'>
                            <button className='h-14 w-14 bg-white rounded-full flex active:bg-gray-200 items-center justify-center' onClick={() => { navigate('/app/cheerbuddy/rewards') }} >
                                <svg className="w-[37px] h-[37px] text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z" />
                                </svg>


                            </button>
                            <p className='text-tertiary font-poppins text-sm text-center'>
                                Rewards
                            </p>
                        </div>
                        <div className='items-center'>
                            <button className='h-14 w-14 bg-white rounded-full flex active:bg-gray-200 items-center justify-center' onClick={() => { navigate('/app/cheerbuddy/guide') }} >
                                <svg xmlns="http://www.w3.org/2000/svg" height="37px" viewBox="0 -960 960 960" width="37px" fill="#F68220"><path d="M660-160h40v-160h-40v160Zm20-200q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM200-800v640-640 200-200Zm80 400h147q11-23 25.5-43t32.5-37H280v80Zm0 160h123q-3-20-3-40t3-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v92q-19-6-39-9t-41-3v-40H480v-200H200v640h227q11 23 25.5 43T485-80H200Zm480-400q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480Z"/></svg>
                            </button>
                            <p className='text-tertiary font-poppins text-sm text-center'>
                                Guide
                            </p>
                        </div>
                    </div>
                    {/* dialog box */}
                    {dialogbox &&
                        <div className="relative bg-custom-ececec rounded-xl p-5 ">
                            <div className="absolute top-[-12px] right-[30px] w-[25px] h-[25px] bg-custom-ececec transform rotate-45 " />
                            <button className="absolute right-4 top-4" onClick={() => { setDialogbox(false) }}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 15L1 1M15 1L1 15" stroke="#595959" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                            <p className="text-[14px] font-bold font-Lato z-10">Meet CheerBuddy</p>
                            <p className="text-[12px] text-custom-444341 font-Lato pt-1 z-10">
                                Discover CheerBuddy with our easy-to-follow guide and earn rewards.
                            </p>
                        </div>}
                    
                    <div className='grid py-4 gap-y-2'>
                        {postData.map((post, index) => (
                            <CBShareCard key={index} title={post.title} image={post.image} id={post.id} url={post.url} caption={post.caption} setUrl={setUrl} setCaption={setCaption} setShowModal={setShowModal} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CheerBuddy;
