import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function RoleSelect() {
    const navigate = useNavigate();

    return (
        <div className='h-screen relative overflow-hidden w-full'>
            <div className="flex flex-col items-center justify-center bg-primary">
                <div className="relative">
                    <h1 className="absolute text-white text-center text-3xl font-bold" style={{ left: '50%', top: '35%', transform: 'translate(-50%, -50%)' }}>Engage</h1>
                    <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/elipse.svg`} alt="ElipseIcon" />
                </div>
                <div className="absolute w-full bottom-0 left-0 bg-white p-8 rounded-t-2xl">
                    <div>
                        <p className="text-lg font-medium text-gray-600 text-center pt-2">
                            Select who you are?
                        </p>
                    </div>
                    <div className="space-y-5 mt-10 mb-5">
                        <button
                            className="flex items-center justify-center w-full h-16 bg-primary rounded-full text-white text-lg font-bold space-x-7"
                            onClick={() => navigate('/createprofile')}>
                            <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/roleStudent.svg`} alt="ElipseIcon" />
                            <span>Student</span>
                        </button>
                        <button
                            className="flex items-center justify-center w-full h-16 bg-primary rounded-full text-white text-lg font-bold space-x-7"
                            onClick={() => navigate('/createprofile')}>
                            <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/roleFaculty.svg`} alt="Faculty" />
                            <span>Faculty</span>
                        </button>
                        <button
                            className="flex items-center justify-center w-full h-16 bg-primary rounded-full text-white text-lg font-bold space-x-7"
                            onClick={() => navigate('/createprofile')}>
                            <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/roleAlumni.svg`} alt="Alumni" />
                            <span>Alumni</span>
                        </button>
                        <button
                            className="flex items-center justify-center w-full h-16 bg-primary rounded-full text-white text-lg font-bold space-x-9"
                            onClick={() => navigate('/createprofile', { state: { type: 'other' } })}>
                            <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/roleOther.svg`} alt="Other" />
                            <span>Other</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
