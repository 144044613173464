import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Onboarding = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const slides = [
        {
            image: 'images/public/engageintro.png',
            title: 'Welcome to LPU Engage!',
            description: 'Join our vibrant community of students, teachers, and alumni, and be rewarded for your contributions and dedication to social causes and community growth.',
        },
        {
            image: 'images/public/cbintro.png',
            title: 'Cheer Buddy',
            description: (
                <div className="grid grid-cols-5 items-center justify-center w-full mt-8">
                    <div className="flex flex-col items-center">
                        <img src="icons/cheerBuddyIcons/engage.svg" alt="Earn Rewards" className="w-100" />
                        <p className="mt-2 text-center text-xs">Share Post</p>
                    </div>
                    <div className="flex items-center">
                        <img src="icons/cheerBuddyIcons/arcone.svg" alt="" className='w-100' />
                    </div>
                    <div className="flex flex-col items-center">
                        <img src="icons/cheerBuddyIcons/submitentry.svg" alt="Earn Rewards" className="w-100" />
                        <p className="mt-2 text-center text-xs">Submit Entry</p>
                    </div>
                    <div className="flex items-center">
                        <img src="icons/cheerBuddyIcons/arctwo.svg" alt="" className='w-100' />
                    </div>
                    <div className="flex flex-col items-center">
                        <img src="icons/cheerBuddyIcons/earnrewards.svg" alt="Earn Rewards" className="w-100" />
                        <p className="mt-2 text-center text-xs">Earn Rewards</p>
                    </div>
                </div>
            ),
        },
    ];

    const nextSlide = () => {
        if (currentSlide === slides.length - 1) {
            navigate('/app/cheerbuddy/onboarding');
        } else {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    return (
        <div className="h-screen flex flex-col items-center justify-between bg-white">
            <div className="flex w-full flex-col items-center justify-center flex-grow relative overflow-hidden">
                <div className="absolute inset-0 flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                    {slides.map((slide, index) => (
                        <div key={index} className="flex flex-col items-center justify-center flex-shrink-0 w-full h-full">
                            {isLoading && (
                                <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
                                    <div className="loader">Loading...</div>
                                </div>
                            )}
                            <img src={slide.image} alt="" className="w-2/3" onLoad={handleImageLoad} />
                            <h1 className="text-black text-xl font-bold text-center mt-8">
                                {slide.title}
                            </h1>
                            <div className="text-sm text-justify mt-5 px-4 w-100">
                                {typeof slide.description === 'string' ? (
                                    <p>{slide.description}</p>
                                ) : (
                                    slide.description
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full flex flex-col items-center mb-8">
                <div className="w-full flex justify-between px-6">
                    <div className="flex-grow flex items-center justify-center w-100 space-x-2">
                        {slides.map((_, idx) => (
                            <span
                                key={idx}
                                className={`h-2 w-2 rounded-full ${idx === currentSlide ? 'bg-black' : 'bg-gray-400'}`}
                            />
                        ))}
                    </div>
                    <button
                        onClick={nextSlide}
                        className="text-primary font-semibold text-lg ml-auto">
                        {currentSlide === slides.length - 1 ? 'Done' : 'Next'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Onboarding;
