import React, { useState } from 'react';

const ProcessingCard = ({ cb }) => {
    const [contentVisible, setContentVisible] = useState(false);

    const renderIcon = (url) => {
        switch (url) {
            case "facebook":
                return (<img src={`/icons/cheerBuddyIcons/facebookCB.svg`} alt=''/>);
            case "linkedin":
                return (<img src={`/icons/cheerBuddyIcons/linkedinCB.svg`} alt=''/>);
            case "twitter":
                return (<img src={`/icons/cheerBuddyIcons/xCB.svg"`} alt=''/>);
            case "x":
                return (<img src={`/icons/cheerBuddyIcons/xCB.svg`} alt=''/>);
            case "instagram":
                return (<img src={`/icons/cheerBuddyIcons/instagramCB.svg`} alt=''/>);
            case "snapchat":
                return (<img src={`/icons/cheerBuddyIcons/snapchatCB.svg`} alt=''/>);
            default:
                return null;
        }
    };

    const extractDomain = (url) => {
        const domain = new URL(url).hostname;
        const domainParts = domain.replace("www.", "").split(".");
        if (domainParts.length >= 2) {
            return domainParts[domainParts.length - 2];
        }
        return null;
    }

    return(
        <div className="w-full mt-10 border border-secondary rounded-lg bg-white overflow-hidden">
            <button
                className={`w-full flex items-center gap-4 pr-6 focus:outline-none ${contentVisible ? 'border-b border-secondary' : ''}`}
                onClick={() => setContentVisible(!contentVisible)}
            >
                <div className='flex flex-row'>
                    <div className={`bg-secondary h-16 w-16 flex items-center justify-center pl-4 pr-2 rounded-l-lg ${contentVisible ? 'rounded-l-none' : ''}`}>
                        {renderIcon(extractDomain(cb?.url))}
                    </div>
                    <svg className="h-16 right-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 63" fill="none">
                        <path d="M13 32.5L0 0V63L13 32.5Z" fill="#FFE5D0" />
                    </svg>
                </div>
                <div className="flex-1 h-16 flex items-center overflow-hidden">
                    <p className="text-base text-custom-818f9c line-clamp-1">{cb?.url}</p>
                </div>
            </button>
            {contentVisible && (
                <div className="bg-white p-4 rounded-b-lg">
                    <div className="">
                        <div className="flex gap-2">
                            <p className="font-medium text-tertiary">Status:</p>
                            <p className="text-custom-818f9c truncate">{cb?.status ? cb.status.charAt(0).toUpperCase() + cb.status.slice(1) : ''}</p>
                        </div>
                        {cb?.status === "pending" &&
                            <p className="text-sm text-custom-959090">Your submission is undergoing verification</p>
                        }
                        <p className="pt-2 text-custom-818f9c ">{cb?.description}</p>                        
                    </div>
                </div>
            )}
        </div>
    )
}
export default ProcessingCard;