import React, { useState, useEffect } from 'react';
import Post from '../../main/elements/Post';
import { Helmet } from 'react-helmet-async';
import CBSharePopup from '../../main/elements/CBSharePopup';

const ViewPost = ({ passPost, params }) => {
    const [postUrl, setPostUrl] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [initialized, setInitialized] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [url, setUrl] = useState(false);
    const [caption, setCaption] = useState(false);

    useEffect(() => {
        if (!initialized) {
            updateMetadata(passPost);
            setInitialized(true);
        }
    }, [initialized, params, passPost]);

    const updateMetadata = (post) => {
        if (!post) return;
        setPostUrl(`https://engage.webhizzy.in/app/feed/${post._id}`);
        setDescription(post.description);
        setImage(post.images[0]?.image_480?.publicURL || '');
    };

    return (
        <>
            {initialized && (
                <Helmet>
                    <meta property="og:url" content={postUrl} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content='Lovely Professional University - LPU' />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={image} />
                    <meta name="twitter:card" content="Post" />
                    <meta name="twitter:site" content="@lpuuniversity" />
                    <meta name="twitter:title" content='Lovely Professional University - LPU' />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={image} />
                </Helmet>
            )}
            <div className="h-screen overflow-hidden">
                    <div className="flex h-screen bg-white">
                        <Post post={passPost} setUrl={setUrl} setCaption={setCaption} setShowModal={setShowModal} />
                    </div>
            </div>
            <CBSharePopup
                url={url}
                caption={caption}
                showModal={showModal}
                setShowModal={setShowModal}
            />
        </>
    );
};

export default ViewPost;
