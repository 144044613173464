import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Header({ isBack, text, children }) {
    const navigate = useNavigate();
    return (
        <div className="sticky top-0 z-50 w-full max-w-md  flex items-center justify-center py-4 px-2 bg-white shadow-md ">
            {isBack &&
                <button onClick={() => navigate(-1)} className="absolute left-5 flex justify-center w-6">
                    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 1L1 10.0097L11 19.0193" stroke="#4B4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            }
            <p className="font-bold text-lg text-custom-383838">
                {text}
            </p>
            <div className="absolute right-5 flex items-center">
                {children}
            </div>
        </div>
    );
}
