import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import Discover from '../pages/discover/Discover';
import CheerBuddy from '../pages/cheerbuddy/CheerBuddy';
import SubmitEntry from '../pages/cheerbuddy/SubmitEntry';
import Status from '../pages/cheerbuddy/Status';
import Rewards from '../pages/cheerbuddy/Rewards';
import Wallet from '../pages/cheerbuddy/Wallet';
import Guide from '../pages/cheerbuddy/Guide';
import Settings from '../pages/settings/Settings';
import ChangeEmail from '../pages/settings/ChangeEmail';
import ChangePassword from '../pages/settings/ChangePassword';
import EditProfile from '../pages/settings/EditProfile';
import PrivacyPolicy from '../pages/settings/PrivacyPolicy';
import TermsConditions from '../pages/settings/TermsConditions';
import AidForAspiring from '../pages/generalPages/AidForAspiring';
import ApplyJobs from '../pages/generalPages/ApplyJobs';
import BeAMentor from '../pages/generalPages/BeAMentor';
import Masterboard from '../pages/generalPages/Masterboard';
import ReferABuddy from '../pages/generalPages/ReferABuddy';
import StartupProgram from '../pages/generalPages/StartupProgram';
import Feed from '../pages/feed/Feed';
import Search from '../pages/discover/Search';
import ViewProfile from '../pages/discover/ViewProfile';
import DiscoverFeed from '../pages/discover/Feed';
import NotFound from '../pages/NotFound';
import CBSharePopup from './elements/CBSharePopup'
import Profile from '../pages/profile/Profile';
import ViewPost from '../pages/ViewPost/viewPost';

const MyComponent = ({ url, setUrl, caption, setCaption, showModal, setShowModal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [index, setIndex] = useState(getIndexFromPath(location.pathname));
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [isInteractingWithMenu, setIsInteractingWithMenu] = useState(false);
  const [showVideoPopUp, setShowVideoPopUp] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const fullscreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement;

      setIsFullscreen(!!fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleFullscreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen();
    }
  };


  useEffect(() => {
    setIndex(getIndexFromPath(location.pathname));
    showPopup(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (index === 1) {
      const timer = setTimeout(() => {
        setIsNavVisible(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setIsNavVisible(true);
    }
  }, [isNavVisible, index]);

  function getIndexFromPath(path) {
    if (path.startsWith('/app/discover')) {
      return 0;
    } else if (path.startsWith('/app/feed')) {
      return 1;
    } else if (path.startsWith('/app/cheerbuddy')) {
      return 2;
    } else {
      return 1;
    }
  }

  function showPopup(path) {
    if (path.startsWith('/app/cheerbuddy/onboarding')) {
      setShowVideoPopUp(true);
    }
  }

  const params = location.pathname.split('/').slice(3);

  const handleChangeIndex = (index) => {
    setIndex(index);
    switch (index) {
      case 0:
        navigate('/app/discover');
        break;
      case 1:
        navigate('/app/feed');
        break;
      case 2:
        navigate('/app/cheerbuddy');
        break;
      default:
        navigate('/app/feed');
        break;
    }
  };

  const handleNavigate = (targetIndex) => {
    handleChangeIndex(targetIndex);
  };

  const handleFeedSwipe = (index) => {
    if (index > currentIndex) {
      setIsNavVisible(false);
    } else if (index < currentIndex) {
      setIsNavVisible(true);
    }
    setCurrentIndex(index);
  };

  const getTransformValue = () => {
    switch (index) {
      case 0:
        return 'translateX(38%)';
      case 2:
        return 'translateX(-38%)';
      default:
        return 'translateX(0)';
    }
  };


  return (
    <div className="relative h-screen w-full overflow-x-hidden">
      {showVideoPopUp && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50'>
          <div className="relative bg-white p-2 rounded-lg">
            <button
              onClick={() => { setShowVideoPopUp(false); navigate('/app/cheerbuddy'); }}
              className="absolute right-3 top-3 rounded-full p-0.5 bg-black bg-opacity-80 z-50"
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff">
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </button>
            <video
              ref={videoRef}
              controls
              className={`w-full h-auto ${isFullscreen ? 'rotate-90' : ''}`}
              onDoubleClick={handleFullscreen}
            >
              <source src="/videos/cheerbuddyGuide.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      )}
      <div className={`pointer-events-none absolute top-0 left-0 w-full z-10 transition-transform duration-300 shadow-md ${isNavVisible ? 'translate-y-0' : '-translate-y-full'}`}>
        <div
          className="pointer-events-auto grid grid-cols-3 justify-between items-center py-4 px-2 bg-white shadow-b-md transition-transform duration-300"
          style={{ transform: getTransformValue() }}
        >
          <div className="flex justify-start">
            <button onClick={() => handleNavigate(0)} className={`flex items-center justify-start ${(index === 0) ? 'ps-1 sm:ps-4' : ''}`}

            >
              <svg className={`me-1 ${(index === 1) ? '' : 'hidden'}`} width="12" height="14" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 1L1 10.0097L11 19.0193" stroke="#f68220" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className='font-bold'>Discover</span>
            </button>
          </div>
          <div className="flex justify-center">
            <button onClick={() => handleNavigate(1)} className={`flex items-center justify-center`}>
              <svg className={`me-1 ${(index === 2) ? '' : 'hidden'}`} width="12" height="14" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 1L1 10.0097L11 19.0193" stroke="#f68220" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className='font-bold'>My Feed</span>
              <svg className={`ms-1 ${(index === 0) ? '' : 'hidden'}`} width="12" height="14" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 19.0193L11 10.0097L1 0.999998" stroke="#f68220" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <div className={`flex justify-end`}>
            <button onClick={() => handleNavigate(2)} className={`flex items-center justify-end ${(index === 2) ? 'translate-x-[0.6rem] sm:translate-x-1' : ''}`}>
              <span className='font-bold'>Cheer Buddy</span>
              <svg className={`ms-1 ${(index === 1) ? '' : 'hidden'}`} width="12" height="14" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 19.0193L11 10.0097L1 0.999998" stroke="#f68220" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
        <div className={`absolute flex top-11 left-0 w-full z-20 justify-center ${isNavVisible ? '' : 'hidden'}`}><div className='w-8 h-1 rounded-full bg-primary'>&nbsp;</div></div>
      </div>
      {/* Popup Start */}
      <CBSharePopup
        url={url}
        caption={caption}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      {/* Popup End */}
      <div className="absolute top-0 left-0 w-full h-full">
        <SwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
          disabled={isInteractingWithMenu}
        >
          <div><Discover setIsInteractingWithMenu={setIsInteractingWithMenu} /></div>
          <div><Feed onSwipe={handleFeedSwipe} params={params}
            setUrl={setUrl}
            setCaption={setCaption}
            setShowModal={setShowModal} /></div>
          <div><CheerBuddy
            setUrl={setUrl}
            setCaption={setCaption}
            setShowModal={setShowModal} /></div>
        </SwipeableViews>
      </div>
    </div>
  );
};

const App = () => {
  const [passPost, setPassPost] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState(false);
  const [caption, setCaption] = useState(false);

  return (
    <Routes>
      <Route path="/" element={<MyComponent
        url={url}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal} />} />
      <Route path="discover" element={<MyComponent
        url={url}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal} />} />
      <Route path="feed" element={<MyComponent
        url={url}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal} />} />
      <Route path="feed/:id" element={<MyComponent
        url={url}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal} />} />
      <Route path="discover/feed/:postId" element={<DiscoverFeed 
        url={url}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal}
        />} />
      <Route path="discover/post" element={<ViewPost
        passPost={passPost}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal} />} />
      <Route path="profile/post" element={<ViewPost
        passPost={passPost}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal} />} />
      <Route path="cheerbuddy" element={<MyComponent
        url={url}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal} />} />
      <Route path="cheerbuddy/onboarding" element={<MyComponent
        url={url}
        setUrl={setUrl}
        caption={caption}
        setCaption={setCaption}
        showModal={showModal}
        setShowModal={setShowModal} />} />
      <Route path="cheerbuddy/submitentry" element={<SubmitEntry />} />
      <Route path="cheerbuddy/status" element={<Status />} />
      <Route path="cheerbuddy/rewards" element={<Rewards />} />
      <Route path="cheerbuddy/wallet" element={<Wallet />} />
      <Route path="cheerbuddy/guide" element={<Guide />} />
      <Route path="profile" element={<Profile setPassPost={setPassPost} />} />
      <Route path="settings" element={<Settings />} />
      <Route path="settings/changeemail" element={<ChangeEmail />} />
      <Route path="settings/changepassword" element={<ChangePassword />} />
      <Route path="settings/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="settings/termsconditions" element={<TermsConditions />} />
      <Route path="settings/editprofile" element={<EditProfile />} />
      <Route path="discover/search" element={<Search setPassPost={setPassPost} />} />
      <Route path="discover/viewProfile/:id" element={<ViewProfile />} />
      <Route path="applyJobs" element={<ApplyJobs />} />
      <Route path="beAMentor" element={<BeAMentor />} />
      <Route path="masterboard" element={<Masterboard />} />
      <Route path="referABuddy" element={<ReferABuddy />} />
      <Route path="startupProgram" element={<StartupProgram />} />
      <Route path="aidForAspiring" element={<AidForAspiring />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
};

export default App;
