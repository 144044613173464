import React from 'react';

export default function Button({ isLoading, text, onClick, variant, buttonClass, textClass, type }) {

    const getButtonClasses = () => {
        let baseClasses = "w-full md:w-46 flex items-center justify-center h-12 rounded-full";
        if (variant === 'default') {
            baseClasses += "";
        } else if (variant === 'primary') {
            baseClasses += " bg-primary";
        }
        if (buttonClass) {
            baseClasses += ` ${buttonClass}`;
        }
        return baseClasses;
    };

    const getTextClasses = () => {
        let baseClasses = "text-lg font-bold";
        if (variant === 'default') {
            baseClasses += " text-primary";
        } else if (variant === 'primary') {
            baseClasses += " text-white";
        }
        if (textClass) {
            baseClasses += ` ${textClass}`;
        }
        return baseClasses;
    };


    return (
        <button onClick={onClick} className={getButtonClasses()} disabled={isLoading} type={type}>
            {isLoading ?
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                </svg>
                :
                <span className={getTextClasses()}>
                    {text}
                </span>
            }
        </button>
    );
}
