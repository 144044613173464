import React, { useEffect, useRef, useState } from 'react';
import Header from '../../main/Header';

const Guide = () => {
    const faqQuestions = [
        {
            question: "What should I share?",
            answer: "The recommended posts to be shared are available on Cheerbuddy dashboard page."
        },
        {
            question: "What is the process for submitting an entry?",
            answer: "After sharing the post on your social media platform, tap on Share and copy the link(URL) of your post. Then, go to the CheerBuddy dashboard, tap on Submit Entry, paste the copied link, and tap on submit."
        },
        {
            question: "How do I earn rewards?",
            answer: "You will have to submit your post's link on submit entry, you entry will be verified by our moderators. After verification, you will earn coins which you can use to redeem rewards."
        },
        {
            question: "Where can i check my rewards?",
            answer: "Your rewards are all visible in the Rewards Page. Open Cheerbuddy dashboard and tap on Rewards."
        },
        {
            question: "How long does it take for our entry's verification?",
            answer: "Your entry will be verified by our moderators within 15 days from the day of your submission."
        },
    ];

    const [isFullscreen, setIsFullscreen] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const handleFullscreenChange = () => {
            const fullscreenElement =
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement;

            setIsFullscreen(!!fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('msfullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('msfullscreenchange', handleFullscreenChange);
        };
    }, []);

    const handleFullscreen = () => {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) {
            videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) {
            videoRef.current.msRequestFullscreen();
        }
    };

    return (
        <div className="h-full bg-custom-fcfff7">
            <Header isBack={true} text={"Guide"} />
            <div className='p-4'>
                <div className='aspect-video w-full rounded-lg border overflow-hidden shadow-md'>
                    <video
                        ref={videoRef}
                        controls
                        className={`w-full h-auto ${isFullscreen ? 'rotate-90' : ''}`}
                        onDoubleClick={handleFullscreen}
                    >
                        <source src="/videos/cheerbuddyGuide.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className='px-1 pb-4'>
                    <div className="grid grid-cols-5 items-center justify-center w-full mt-8">
                        <div className="flex flex-col items-center ">
                            <img src="/icons/cheerBuddyIcons/engage.svg" alt="Earn Rewards" className="w-100 rounded-3xl" />
                        </div>
                        <div className="flex items-center">
                            <img src="/icons/cheerBuddyIcons/arcone.svg" alt="" className='w-100' />
                        </div>
                        <div className="flex flex-col items-center">
                            <img src="/icons/cheerBuddyIcons/submitentry.svg" alt="Earn Rewards" className="w-100 rounded-3xl" />
                        </div>
                        <div className="flex items-center">
                            <img src="/icons/cheerBuddyIcons/arctwo.svg" alt="" className='w-100' />
                        </div>
                        <div className="flex flex-col items-center">
                            <img src="/icons/cheerBuddyIcons/earnrewards.svg" alt="Earn Rewards" className="w-100 rounded-3xl" />
                        </div>
                        <div className="flex flex-col items-center ">
                            <p className="mt-2 text-center text-xs">Share Post</p>
                        </div>
                        <div></div>
                        <div className="flex flex-col items-center">
                            <p className="mt-2 text-center text-xs">Submit Entry</p>
                        </div>
                        <div></div>
                        <div className="flex flex-col items-center">
                            <p className="mt-2 text-center text-xs">Earn Rewards</p>
                        </div>
                    </div>
                </div>
                <div className='p-4'>
                    <h2 className="mb-2 text-xl font-semibold text-primary dark:text-white">Share Post</h2>
                    <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                        <li>
                            Go to<span className="font-semibold text-gray-900 dark:text-white"> CheerBuddy </span> dashboard.
                        </li>
                        <li>
                            Tap on<span className="font-semibold text-gray-900 dark:text-white"> Share</span> button of the post you want to share.
                        </li>
                        <li>
                            Choose the <span className="font-semibold text-gray-900 dark:text-white">Social Media Platform</span> to share the post on that platform. You can also copy link to share it to your desired platform.
                        </li>
                    </ol>
                    <h2 className="mb-2 text-xl font-semibold text-primary dark:text-white pt-7">Submit Entry</h2>
                    <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                        <li>
                            After sharing the post<span className="font-semibold text-gray-900 dark:text-white"> Tap on Share</span> and <span className="font-semibold text-gray-900 dark:text-white">Copy Link </span>of the post.
                        </li>
                        <li>
                            Go to<span className="font-semibold text-gray-900 dark:text-white"> CheerBuddy </span>dashboard and tap on <span className="font-semibold text-gray-900 dark:text-white">Submit Entry.</span>
                        </li>
                        <li>
                            <span className="font-semibold text-gray-900 dark:text-white">Paste </span> the <span className="font-semibold text-gray-900 dark:text-white">Copied link</span> and tap on submit.
                        </li>
                        <li>
                            You can check the <span className="font-semibold text-gray-900 dark:text-white">Status</span> of your entry in the <span className="font-semibold text-gray-900 dark:text-white">Status Page</span>
                        </li>
                    </ol>
                    <h2 className="mb-2 text-xl font-semibold text-primary dark:text-white pt-7">Earn Rewards</h2>
                    <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
                        <li>
                            Your post will be <span className="font-semibold text-gray-900 dark:text-white">Verified</span> by our <span className="font-semibold text-gray-900 dark:text-white">Moderators.</span>
                        </li>
                        <li>
                            After verification you will <span className="font-semibold text-gray-900 dark:text-white">Earn Coins</span>
                        </li>
                        <li>
                            You can use these <span className="font-semibold text-gray-900 dark:text-white">Coins</span> to redeem <span className="font-semibold text-gray-900 dark:text-white">Rewards.</span>
                        </li>
                    </ol>
                </div>
                <div className=''>
                    <div className='pt-4'>
                        <div className="container p-2 mx-auto xl:px-0 flex w-full flex-col mt-4 items-center justify-center text-center">
                            <div className="text-sm font-bold tracking-wider text-primary uppercase">FAQ</div>
                            <h2 className="mt-3 text-2xl font-bold leading-snug tracking-tight text-tertiary lg:leading-tight lg:text-4xl dark:text-white">
                                Frequently Asked Questions
                            </h2>
                        </div>
                    </div>
                    <div className="grid w-full max-w-2xl p-2 mx-auto rounded-2xl">
                        {faqQuestions.map((faq, index) => (
                            <div key={index} className="mb-3 w-full px-4 py-4 text-left text-tertiary rounded-lg bg-gray-50 dark:bg-trueGray-800 dark:text-gray-200">
                                <details className="group">
                                    <summary className="flex justify-between items-center cursor-pointer list-none">
                                        <span>{faq.question}</span>
                                        <span className="transition group-open:rotate-180 w-5 h-5 text-green-500">
                                            <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                <path d="M6 9l6 6 6-6"></path>
                                            </svg>
                                        </span>
                                    </summary>
                                    <p className="pt-4 text-custom-8b8989 dark:text-gray-300 group-open:animate-fadeIn">
                                        {faq.answer}
                                    </p>
                                </details>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Guide;
