import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ApiProvider } from './contexts/ApiContext';
import Login from './components/auth/Login';
// import Signup from './components/auth/Signup';
import Logout from './components/auth/Logout';
import MainApp from './components/main/MainApp';
import SignUp from './components/auth/SignUp';
import Verification from './components/auth/Verification';
import ForgotPassword from './components/auth/ForgotPassword';
import RoleSelect from './components/auth/RoleSelect';
import CreateProfile from './components/auth/CreateProfile';
import Onboarding from './components/Onboarding';
import Callback from './components/auth/callback';
import InstallPrompt from './components/main/elements/InstallPrompt';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  const [email, setEmail] = useState("");
  const helmetContext = {};

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
          // .then(registration => {
          //   console.log('SW registered: ', registration);
          // })
          // .catch(registrationError => {
          //   console.log('SW registration failed: ', registrationError);
          // });
      });
    }
  })

  return (
    <HelmetProvider context={helmetContext}>
      <div className='max-w-md '>
        <Router>
          <ApiProvider>
            <InstallPrompt />
            <Routes>
              <Route path="/login" element={<Login email={email} setEmail={setEmail} />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/signup" element={<SignUp email={email} setEmail={setEmail} />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/verification" element={<Verification email={email} setEmail={setEmail} />} />
              <Route path="/roleselect" element={<RoleSelect />} />
              <Route path="/createprofile" element={<CreateProfile />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/auth/callback/:token" element={<Callback />} />
              <Route path="/app/*" element={<MainApp />} />
              <Route path="*" element={<Navigate to="/signup" />} />
            </Routes>
          </ApiProvider>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
