import React from 'react';
import Header from '../../main/Header';

const TermsConditions = () => {

    return (
        <div className="h-screen w-full bg-custom-fcfff7 overflow-y-auto">
            <Header isBack={true} text={"Terms & Conditions"}/>
            <div >
                <div className="w-full aspect-w-16 aspect-h-9 mb-4">
                    <img
                        className="w-full h-full object-cover"
                        src={`/images/public/termsAndConditions.png`}
                        alt="Privacy Policy"
                    />
                </div>
                <div className="mx-6 pb-24">
                    <p className="font-lato text-sm text-tertiary">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsConditions;
