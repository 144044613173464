import React, { useState } from 'react';
import InputText from '../../main/InputText';
import Button from '../../main/Button';
import Header from '../../main/Header';
import OTPModal from '../../main/elements/OTPModal';
import api from '../../../api/axiosInstance';
import Snackbar from "../../main/elements/Snackbar";
import { useNavigate } from 'react-router-dom';

const ChangeEmail = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(true);
    const [newEmail, setNewEmail] = useState("");
    const [confirmNewEmail, setConfirmNewEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleOpenModal = () => {
        setShowOTPModal(true);
    };

    const handleCloseModal = () => {
        setShowOTPModal(false);
    };

    const submit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            var newErrors = {};
            const trimmedNewEmail = newEmail.trim();
            const trimmedConfirmNewEmail = confirmNewEmail.trim();
            const trimmedpassword = password.trim();
            if (trimmedConfirmNewEmail !== trimmedNewEmail) {
                newErrors.confirmNewEmail = "Email Mismatch"
            }
            if (Object.keys(newErrors).length > 0) {
                setError(newErrors);
                setIsLoading(false);
                setShowSnackbar(true)
                setSnackbarMessage("Email mismatch")
                return;
            } else {
                const response = await api.patch('/login/email', { email:trimmedNewEmail, currentPassword:trimmedpassword });
                if (response.data.error) {
                    setError(response.data.message);
                    setShowSnackbar(true);
                    setSnackbarMessage(response.data.message);
                } else {
                    setIsLoading(false);
                    handleOpenModal();
                }
            }
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
            console.error(error.message);
            setShowSnackbar(true);
            setSnackbarMessage(error.message)
        }
    }

    return (
        <div className="h-screen max-w-md bg-custom-fcfff7">
            <Header isBack={true} text={"Change Email"} />
            {showSnackbar && (
                <Snackbar
                    message={snackbarMessage}
                    onDismiss={() => setShowSnackbar(false)}
                />
            )}
            <form autoComplete="off" onSubmit={submit}>
                <div className="flex flex-col gap-4 p-6">
                    <InputText placeholder="New Email" onChange={(e) => setNewEmail(e.target.value)} hasError={error.newEmail} type='email' required={true} />
                    <InputText placeholder="Confirm Email" onChange={(e) => setConfirmNewEmail(e.target.value)} hasError={error.confirmNewEmail} type='email' required={true} autoComplete="off" />
                    <InputText placeholder="Password" onChange={(e) => setPassword(e.target.value)} hasError={error.password} type={showPassword ? "password" : "text"} required={true}>
                        <button onClick={(e) => { e.preventDefault(); setShowPassword(!showPassword); }} className="absolute right-4 top-0 h-full flex items-center z-10">
                            <img src={`/icons/authenticationIcons/eye.svg`} alt="Eye Icon" />
                        </button>
                    </InputText>
                </div>
                <div className="flex justify-center w-full max-w-md p-5 absolute bottom-2">
                    <Button text="Send OTP" variant="primary" isLoading={isLoading} type="submit" />
                </div>
            </form>
            {showOTPModal && <OTPModal onClose={handleCloseModal} />}
        </div>
    );
};

export default ChangeEmail;
