import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../api/axiosInstance';
import { getToken, setToken } from '../../utils/secureStorage';

const Callback = () => {
    const [error, setError] = useState({});
    const navigate = useNavigate();
    const { token } = useParams();
    const handelToken = async (token) => {
        const response = await api.post('/token/sociallogin', { token });
        if (response.data.error) {
            setError({ general: response.data.message });
        } else {
            setToken('accessToken', response.data.accessToken);
            setToken('refreshToken', response.data.refreshToken);
            const profile = await api.get('/profile');
            if (!profile.error) {
                if (profile.data.profile.bio === undefined) {
                    navigate(`/createprofile?name=${profile.data.profile.name}`);
                } else {
                    navigate(`/app`);
                }
            }

        }

    };

    useEffect(() => {
        handelToken(token);
    }, [token]);

    return (
        <div>
            <h1>Redirecting...</h1>
        </div>
    );
};

export default Callback;
