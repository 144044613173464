import { useEffect, useState } from 'react';
import { useApiContext } from '../contexts/ApiContext';
import api from '../api/axiosInstance';

const useCachedFetch = (key, url) => {
  const { getApiData, setApiData } = useApiContext();
  const [data, setData] = useState(getApiData(key));
  const [loading, setLoading] = useState(!data);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      if (!data) {
        try {
          setLoading(true);
          const response = await api.get(url);
          setData(response.data);
          setApiData(key, response.data);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [key, url, data, setApiData]);

  return { data, loading, error, refetch: () => setData(null) };
};

export default useCachedFetch;
