import React from 'react';
import ArrivingSoon from '../../main/elements/ArrivingSoon';
import Header from '../../main/Header';
;

const ApplyJobs = () => {
    return (
        <div className='h-screen relative overflow-y-auto overflow-hidden w-full '>
            <Header isBack={true} text={"Apply Jobs"}/>
            <div className='flex justify-end pt-4'>
                <ArrivingSoon/>
            </div>
        </div>
    )
};

export default ApplyJobs;
