import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import useCachedFetch from '../../../hooks/useCachedFetch';
import Post from '../../main/elements/Post';
import { Helmet } from 'react-helmet-async';
import api from '../../../api/axiosInstance';

const Feed = ({ onSwipe, params, setUrl, setCaption, setShowModal }) => {
  const [postUrl, setPostUrl] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [index, setIndex] = useState(0);
  const [posts, setPosts] = useState([]);
  const [urlPosts, setUrlPosts] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const handleChangeIndex = (index) => {
    setIndex(index);
    onSwipe(index);
  };

  const { data: feed, loading: feedLoading, refetch: refetchFeed } = useCachedFetch('feed', '/feed');

  useEffect(() => {
    if (!feedLoading && feed) {
      setPosts(feed['feed']);
    }
  }, [feedLoading, feed]);

  useEffect(() => {
    const fetchPost = async (postId) => {
      try {
        const response = await api.get(`/post/${postId}`);
        if (response.data.error) {
          console.log(response.data.message);
        } else {
          setUrlPosts(response.data.post);
          updateMetadata(response.data.post);
        }
      } catch (err) {
        console.error('Error fetching post:', err);
      }
    };

    if (!initialized && params.length > 0) {
      fetchPost(params[0]);
      setInitialized(true);
    }
  }, [initialized, params]);

  const updateMetadata = (post) => {
    if (!post) return;
    setPostUrl(`https://engage.webhizzy.in/app/feed/${post._id}`);
    setDescription(post.description);
    setImage(post.images[0]?.image_480?.publicURL || '');
  };

  return (
    <>
      {initialized && (
        <Helmet>
          <meta property="og:url" content={postUrl} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content='Lovely Professional University - LPU' />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta name="twitter:card" content="Post" />
          <meta name="twitter:site" content="@lpuuniversity" />
          <meta name="twitter:title" content='Lovely Professional University - LPU' />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
        </Helmet>
      )}
      <div className="h-screen overflow-hidden">
        {feedLoading ? (
          // Loading skeleton as before
          <>
            <div className="h-[8%] w-full flex items-center gap-4 px-4 pt-2 pb-2">
              <div className="bg-gray-200 h-10 aspect-square border rounded-full" />
              <div role="status" className="w-full max-w-md animate-pulse">
                <div className="w-36 h-8 bg-gray-200 rounded-md dark:bg-gray-800" />
              </div>
            </div>
            <div role="status" className="w-full h-[62%] max-w-md animate-pulse">
              <div className="w-full h-full bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse pt-4 px-3">
              <div className="w-36 h-8 bg-gray-200 rounded-md dark:bg-gray-800 mt-3" />
              <div className="w-full h-8 bg-gray-200 rounded-md dark:bg-gray-800 mt-3" />
              <div className="w-full h-8 bg-gray-200 rounded-md dark:bg-gray-800 mt-3" />
              <div className="w-72 self-end h-8 bg-gray-200 rounded-md dark:bg-gray-800 mt-3" />
            </div>
            <div className="h-auto pb-5 left-0 right-0 bg-white">
              <div className="flex justify-around pt-4 items-center animate-pulse">
                <div className="h-10 w-10 bg-gray-200 rounded-full dark:bg-gray-800 mt-3" />
                <div className="w-36 h-10 bg-gray-200 rounded-full dark:bg-gray-800 mt-3" />
                <div className="h-10 w-10 bg-gray-200 rounded-full dark:bg-gray-800 mt-3" />
              </div>
            </div>
          </>
        ) : (
          <SwipeableViews
            axis="y"
            index={index}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
            containerStyle={{ height: '100vh' }}
            style={{ height: '100vh' }}
          >
            {urlPosts && (
              <div className="flex h-screen bg-white">
                <Post post={urlPosts} setUrl={setUrl} setCaption={setCaption} setShowModal={setShowModal} />
              </div>
            )}
            {posts.map((post, idx) => (
              <div key={idx} className="flex h-screen bg-white">
                <Post post={post} setUrl={setUrl} setCaption={setCaption} setShowModal={setShowModal} />
              </div>
            ))}
          </SwipeableViews>
        )}
      </div>
    </>
  );
};

export default Feed;
