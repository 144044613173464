import React from "react";

export default function WalletCard({data}) {
    data.type = "credit";
    return(
        <div>
            <div className="relative w-full max-w-md flex flex-row place-content-between gap-2 px-3 py-2">
                <div className="pb-0.5">
                <p className="font-semibold text-tertiary text-base">
                    {data.title}
                    Cheerbuddy reward 
                </p><p className="font-normal text-custom-959090 text-sm">
                    Date {data.date}
                    02 Jun 2025
                </p>
                </div>
                <p className={`text-end font-semibold text-xl 
                    ${data.type==="credit" ? 'text-green-600' : 'text-custom-ea1111' }
                    `}>
                    + {data.amount}
                    30
                </p>
            </div>
            <div className="bg-custom-e3dEd8 h-0.5 w-full max-w-md"/>
        </div>
    )
}