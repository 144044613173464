import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCachedFetch from "../../../hooks/useCachedFetch";
import api from '../../../api/axiosInstance';

const Post = ({ post, setShowModal, setUrl, setCaption }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [like, setLike] = useState(post.hasLiked);
  const [likes, setLikes] = useState(post.likes);
  const [saved, setSaved] = useState(post?.hasSaved);
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const { refetch: refetchSaved } = useCachedFetch('savedPosts', '/profile/saved');

  useEffect(() => {
    if (post.hasLiked && post.likes < 1) {
      setLikes(1);
    } else {
      setLike(post.hasLiked);
    }
  }, [post]);

  const handleLike = async () => {
    const newLikeStatus = !like;
    setLike(newLikeStatus);
    setLikes(likes + (newLikeStatus ? 1 : -1));

    try {
      const response = await api.post(`/post/${post._id}/like`);
      if (response.data.error) {
        setError(response.data.message);
        setLike(!newLikeStatus);
        setLikes(likes);
      }
    } catch (err) {
      setError('An error occurred while updating the like status');
      setLike(!newLikeStatus);
      setLikes(likes);
    }
  };

  const handleSave = async () => {
    const newSavedStatus = !saved;
    setSaved(newSavedStatus);

    try {
      const response = await api.post(`/post/${post._id}/save`);
      console.log(response.data.message);
      
      if (response.data.error) {
        setError(response.data.message);
        setSaved(!newSavedStatus);
      }
    } catch (err) {
      setError('An error occurred while saving the post');
      setSaved(!newSavedStatus);
    } finally {
      refetchSaved();
    }
  };

  const handleImageChange = (direction) => {
    setCurrentImageIndex((prevIndex) => {
      if (direction === 'next') {
        return (prevIndex + 1) % post.images.length;
      } else {
        return (prevIndex - 1 + post.images.length) % post.images.length;
      }
    });
  };

  useEffect(() => {
    const calculateHeight = () => {
      const charCount = post.description.length;
      const deviceWidth = window.innerWidth;
      const averageCharsPerLine = Math.floor(deviceWidth / 10); // Assuming average 10px per character
      const lineHeight = 24; // Assuming line height of 24px
      const lines = Math.ceil(charCount / averageCharsPerLine);
      const fullHeight = lines * lineHeight;
      const maxHeight = window.innerHeight * 0.4; // 40% of the viewport height
      return Math.min(fullHeight, maxHeight);
    };
    setDescriptionHeight(calculateHeight());
  }, [post.description]);

  const handleProfileClick = (id) => {
    navigate(`/app/discover/viewProfile/${id}`);
};

  return (
    <div className="w-full max-w-md h-screen overflow-hidden relative">
      {/* topbar */}
      <div className="h-[8%] w-full flex items-center justify-between gap-4 px-4 pt-2 pb-2">
        <div className='flex items-center gap-4' onClick={() => {handleProfileClick(post.of)}}>
          <img src={`${post.profile.image.image_480.publicURL}`} alt="" className="bg-red-400 h-10 w-10 border rounded-full" />
          <div className=''>
            <div className="flex gap-4">
              <p className="font-medium">{post.profile.name}</p>
              <div className="h-6 flex flex-row items-center bg-secondary w-fit rounded-full px-2 py-1">
                <img src={`/icons/postIcons/newComerIcon.svg`} className='h-4 w-4' alt="" />
                <span className="text-primary text-sm pl-2">{post.profile.badge}</span>
              </div>
            </div>
            <p className="text-gray-500 text-xs">{post.location}</p>
          </div>
        </div>
        <button className='flex' onClick={handleSave}>
          <img src={`/icons/postIcons/${saved ? 'bookmarkActive.svg' : 'bookmarkInactive.svg'}`} className='h-6' alt='save'/>
        </button>
      </div>

      {/* post */}
      <div className="h-[62%] relative">
        <div className="w-full h-full overflow-hidden relative">
          {post.images.map((image, index) => (
            <img
              key={index}
              src={image.image_480.publicURL}
              srcSet={`${image.image_480.publicURL} 480w, 
           ${image.image_720.publicURL} 720w, 
           ${image.image_1080.publicURL} 1080w`}
              sizes="(max-width: 480px) 480px,
         (max-width: 720px) 720px,
         1080px"
              alt=""
              className={`absolute inset-0 w-full h-full object-cover transition-transform duration-300 ease-in-out ${index === currentImageIndex ? 'translate-x-0' : index < currentImageIndex ? '-translate-x-full' : 'translate-x-full'}`}
              onClick={(e) => {
                const rect = e.target.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const leftThreshold = rect.width * 0.3;
                const rightThreshold = rect.width * 0.7;
                if (x < leftThreshold) {
                  handleImageChange('prev');
                } else if (x > rightThreshold) {
                  handleImageChange('next');
                }
              }}
            />
          ))}
        </div>
        {post.images.length > 1 && (
          <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-2">
            {post.images.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${currentImageIndex === index ? 'bg-primary' : 'bg-secondary'}`}
              ></div>
            ))}
          </div>
        )}
      </div>

      {/* post description */}
      <div className={`relative pt-4 px-4 bg-white rounded-t-2xl ${showMore ? `-translate-y-[40%] h-fit duration-300 shadow-top` : 'max-h-[18%] overflow-hidden duration-300'}`}>
        <p className="text justify overflow-hidden transition-height bg-white">
          {post.description}
        </p>
      </div >

      {/* interaction buttons */}
      <div className="h-auto pb-5 absolute bottom-0 left-0 right-0 bg-white py-2">
        <div className=''>
          <button
            onClick={() => setShowMore(!showMore)}
            className="text-blue-500 px-4">
            {showMore ? 'Read less' : 'Read more'}
          </button>
          <div className='border-t border-gray-300 my-2'></div>
        </div>
        <div className='flex justify-around pt-4 items-center'>
          <button className='flex' onClick={handleLike}>
            <img src={`/icons/postIcons/${like ? 'likeActive.svg' : 'likeInactive.svg'}`} alt="Like" className="h-6 w-6" />
            <span className='text-lg ms-2'>{likes}</span>
          </button>
          <button>
            <span className="font-medium text-primary">Volunteership</span>
          </button>
          <button data-title={post.description} data-url={'https://webhizzy.in/'} onClick={(e) => {
            setUrl(e.currentTarget.dataset.url);
            setCaption(e.currentTarget.dataset.title);
            setShowModal(true);
          }}>
            <img src={`/icons/postIcons/sharePost2.svg`} alt="Share" className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Post;
