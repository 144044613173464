import React from 'react';
import Header from '../../main/Header';
import { useNavigate } from 'react-router-dom';
import Button from '../../main/Button';

const StartupProgram = () => {
    const navigate = useNavigate();
    
    const handleApplyNowClick = () => {
        window.location.href = "mailto:youravgguy0101@gmail.com";
        // navigate(-1);
    };
    
    return (
        <div className='h-screen relative overflow-y-auto overflow-hidden w-full bg-custom-fcff'>
            <Header isBack={true} text={"Startup Program"} />
            <div className="flex flex-col items-center">
                <div className="w-full aspect-w-16 aspect-h-9 py-2.5">
                    <img
                        className="w-full h-full object-cover"
                        src={`/images/public/startupProgram.png`}
                        alt="Startup Program"
                    />
                </div>
                <div className="px-5 pb-20 space-y-3">
                    <p className="text-base font-lato font-semibold text-tertiary">
                        Fostering Innovation and Entrepreneurship
                    </p>
                    <p className="text-sm font-lato font-medium text-tertiary">
                        As part of our university's commitment to fostering innovation and entrepreneurship, we offer a dedicated Startup Program for our students with entrepreneurial aspirations. By participating in our Startup Program, students gain access to a range of exclusive benefits and support services tailored to help them launch and grow their ventures.
                    </p>
                    <p className="text-sm font-lato font-medium text-tertiary">
                        Through industry associations and one-on-one networking opportunities, students gain valuable insights and self-employment tips, empowering them to confidently pursue their entrepreneurial dreams. Grow your startups with the initiatives designed to meet your entrepreneurial needs to help grow your ventures in an environment where aspiring entrepreneurs can thrive, innovate, and confidently embark on their journeys.
                    </p>
                </div>
            </div>
            <div className="flex justify-center items-center w-full px-5">
                <Button variant={"primary"} text={"Apply Now"} isLoading={false} onClick={handleApplyNowClick}/>
            </div>
        </div>
    );
};

export default StartupProgram;
