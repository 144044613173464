import React from "react";

export default function ArrivingSoon() {

   
    return (
        <div className="py-2 px-4 bg-white border border-r-2 border-custom-959090 items-center">
            <p className="text-tertiary text-lg font-medium text-end ">
                Feature arriving soon...
            </p>
        </div>
    );
}
