import React from "react";

const PostCard = ({ data }) => {
    return (
        <button 
            className="w-full flex flex-row gap-5 overflow-hidden rounded-lg bg-[#FFFBFB]"
           
        >
            {data?.images && data?.images.length > 0 && (
                <div className="w-28 rounded-lg">
                    <img 
                        src={data?.images[0]?.image_480?.publicURL ?? ""} 
                        alt="Post"
                        className="w-24 h-20 object-cover border border-custom-f9a826 bg-custom-818f9c rounded-lg"
                    />
                </div>
            )}
            <span className="w-full font-medium text-sm text-tertiary text-start">
                {data?.description?.length > 50 ? `${data?.description?.substring(0, 100)}...` : data?.description}
            </span>
        </button>
    );
}

export default PostCard;
