import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import api from '../api/axiosInstance';
import useCachedFetch from '../hooks/useCachedFetch';

const CropImageUploader = ({image}) => {
  const [imageSrc, setImageSrc] = useState(image ?? null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropping, setCropping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const { refetch: refetchProfile } = useCachedFetch('profile', '/profile');
    
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setCropping(true);
      };
    }
  };

  const handleCrop = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const formData = new FormData();
      formData.append('file', croppedImageBlob, 'profile.jpg');
      setIsLoading(true);
      const response = await api.patch('/profile/picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.error) {
        setError(response.data.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setImageSrc(response.data.data);
        setCropping(false);
        if (image) {
          refetchProfile();
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error.message);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center">
      <div className="relative w-32 h-32 rounded-full overflow-hidden cursor-pointer">
        <img
          src={imageSrc || `/icons/generalIcons/defaultprofile.svg`}
          alt="Profile"
          className="w-full h-full object-cover bg-gray-200"
          onClick={() => document.getElementById('fileInput').click()}
        />
      </div>
      <input
        type="file"
        accept="image/*"
        id="fileInput"
        className="hidden"
        onChange={handleImageChange}
      />
      {cropping && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative w-full h-full">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className='absolute bottom-8 left-1/2 transform w-full -translate-x-1/2  px-4'>
            <button type='button'
              onClick={handleCrop}
              className="w-full md:w-46 flex items-center justify-center h-12 rounded-full bg-primary px-4 py-2"
            >
              {isLoading ?
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                </svg>
                :
                <span className={`text-lg font-bold text-white`}>
                  Crop & Save
                </span>
              }
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CropImageUploader;
