// src/utils/secureStorage.js
import SecureLS from 'secure-ls';

const ls = new SecureLS({ encodingType: 'aes' });

export const setToken = (key, token) => {
  ls.set(key, token);
};

export const getToken = (key) => {
  return ls.get(key);
};

export const removeToken = (key) => {
  ls.remove(key);
};
