import React from "react";

export default function CBShareCard({ title, image, id, url, setUrl, setCaption, setShowModal }) {
    const handleRedirect = (shareUrl) => {
        window.open(shareUrl, '_blank');
    };

    return (
        <div className='flex flex-col items-center'>
            <div className='flex flex-row w-full border rounded-xl items-center bg-white p-2'>
                <img src={image} onClick={() => {handleRedirect(url)}} className='rounded border-4 border-white shadow w-16 object-cover aspect-square' alt='' />
                <div className='flex-grow' onClick={() => {handleRedirect(url)}}>
                    <p className='text-tertiary text-sm font-Poppins px-3 text-start'>
                        {title.length > 50 ? title.substring(0, 50) + "..." : title}
                    </p>
                    <p className='text-custom-959090 text-sm text-start font-normal px-3'>
                        {id}
                    </p>
                </div>
                <button data-title={title} data-url={url} className='p-2  bg-custom-ececec flex flex-col items-center justify-center border rounded-xl' onClick={(e) => {
                    setUrl(e.currentTarget.dataset.url);
                    setCaption(e.currentTarget.dataset.title);
                    setShowModal(true);
                }}>
                    <svg className="w-6 h-6 text-primary mx-2 " viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m20.3359 3.22136-16.46257 5.48753c-.30532.10177-.323.52697-.02719.65374l6.05041 2.59307c.06423.0275.13815.0195.19505-.0211l5.9319-4.23711c.1838-.13131.4103.09517.279.27899l-4.2371 5.93192c-.0406.0569-.0486.1308-.0211.195l2.5931 6.0505c.1267.2958.5519.2781.6537-.0272l5.4875-16.46262c.0912-.27362-.1691-.53393-.4427-.44272z" fill="currentColor" /></svg>
                    <p className='font-Poppins text-primary text-xs'>Share</p>
                </button>
            </div>
        </div>
    );
}
