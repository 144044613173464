// Tabs.js
import React, { useState } from 'react';

const Tab = ({ children, isActive, onClick }) => (
  <button
    className={`px-4 py-2 font-semibold w-52 focus:outline-none ${isActive ? 'border-b-2 border-secondary text-primary' : 'border-b-2 border-custom-fcfff7 text-tertiary'}`}
    onClick={onClick}
  >
    {children}
  </button>
);

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="flex w-full border-b ">
        {React.Children.map(children, (child, index) => (
          <Tab isActive={activeTab === index} onClick={() => setActiveTab(index)}>
            {child.props.title}
          </Tab>
        ))}
      </div>
      <div className="p-4">
        {React.Children.toArray(children)[activeTab]}
      </div>
    </div>
  );
};

const TabContent = ({ children }) => <div>{children}</div>;

export { Tabs, TabContent };
