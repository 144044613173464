import React, { useEffect, useState } from 'react';
import InputText from '../main/InputText';
import Button from '../main/Button';
import api from '../../api/axiosInstance';
import CropImageUploader from '../../utils/CropImageUploader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Snackbar from '../main/elements/Snackbar';

export default function CreateProfile() {
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [id, setId] = useState('');
    const [dob, setDob] = useState('');
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [searchParams] = useSearchParams();
    const [labelVisible, setLabelVisible] = useState(true);
    useEffect(() => {
        setName(searchParams.get('name'));
    }, [searchParams])
    
    const navigate = useNavigate();

    async function updateProfile() {
        // Profile update logic here
        try {
            setIsLoading(true);
            const newErrors = {};
            const trimmedName = name.trim();
            const trimmedBio = bio.trim();
            const interest = [
                "lpu",
                "dsw"
            ]

            if (Object.keys(newErrors).length > 0) {
                setError(newErrors);
                setIsLoading(false);
                setShowSnackbar(true);
                setSnackbarMessage("Error Creating Account");
                return;
            } else {
                const response = await api.patch('/profile/', {
                    name: trimmedName,
                    bio: trimmedBio,
                    interest
                });
                if (response.data.error) {
                    setError(response.data.message);
                    setIsLoading(false);
                    setShowSnackbar(true);
                    setSnackbarMessage(response.data.message);
                } else {
                    setIsLoading(false);
                    console.log(response.data.message);
                    navigate('/onboarding');
                }
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error.message);
            setShowSnackbar(true);
            setSnackbarMessage(error.message);
        }
    }

    return (
        <div className='h-screen relative overflow-hidden w-full'>
        {showSnackbar && (
            <Snackbar
            message={snackbarMessage}
            onDismiss={() => setShowSnackbar(false)}
            />
        )}
            <div className="flex flex-col">
                <div className="relative">
                    <div className="flex justify-center items-center mt-5">
                        <p className="font-medium text-lg text-gray-700">Create Profile</p>
                    </div>
                    <div className="flex flex-col items-center mt-6">
                        <button className="flex justify-center items-center">
                            <CropImageUploader />
                        </button>
                        <p className="text-primary font-bold mt-2">Upload Picture</p>
                    </div>
                    <div className="px-5 mt-5 space-y-4">
                        <InputText placeholder="Enter Name" defaultValue={name} type={"text"} onChange={(e) => setName(e.target.value)} hasError={error.name} />
                        <InputText placeholder="Enter Bio" type={"text"} onChange={(e) => setBio(e.target.value)} hasError={error.bio} />
                        <InputText placeholder="UID / Reg. No." type={"number"} onChange={(e) => setId(e.target.value)} hasError={error.id} />
                        <div className="relative">
                            <label htmlFor="dateTimePickup" id="pickupDateTimeLabel" className={`absolute mx-4 p-1 top-1 text-custom-8b8989 h-8 w-44 bg-white ${labelVisible ? '' : 'hidden'}`} style={{ display: labelVisible ? 'block' : 'none' }} >
                                DOB
                            </label>
                            <input type="date" onFocus={() => setLabelVisible(false)} placeholder="Date" className="border w-full h-10 focus:ring-primary focus:border-primary rounded-full text-black pl-5 pr-9 border-gray-300" required />
                        </div>
                    </div>
                </div>
                <div className="w-full absolute bottom-2 p-5">
                    <Button text="Next" onClick={updateProfile} variant="primary" />
                </div>
            </div>
        </div>
    );
}
