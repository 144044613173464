import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCachedFetch from '../../../hooks/useCachedFetch';

const Discover = ({ setIsInteractingWithMenu }) => {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const { data: explore, loading: exploreLoading, refetch: refetchDiscover } = useCachedFetch('explore', '/explore');

  return (
    <div className='h-screen relative overflow-y-auto overflow-hidden w-full'>
      <div className='absolute top-0 right-0 w-full z-10 p-4 bg-white shadow-md'>
        <div className='justify-left h-6'>
          <button onClick={() => { navigate('/app/settings') }}>
            <svg className='h-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M18.6562 20.897L20.8733 18.6798L20.0925 15.843L20.4327 15.0305L23 13.5818V10.4464L20.44 8.99173L20.1055 8.18067L20.8961 5.34235L18.6774 3.12683L15.8403 3.90748L15.0296 3.56758L13.5808 1H10.4454L8.99072 3.56004L8.17985 3.89446L5.34198 3.10281L3.1267 5.31809L3.90748 8.15567L3.56758 8.96634L1 10.4151V13.5496L3.55774 15.0076L3.89252 15.8193L3.10197 18.6572L5.31809 20.8733L8.15567 20.0925L8.96644 20.4325L10.4153 22.999H13.5498L15.0067 20.4412L15.8183 20.1065L18.6562 20.897ZM18.8527 13.6256L17.9809 15.7078L18.6362 18.0886L18.0678 18.657L15.692 17.9951L13.609 18.8542L12.3873 20.999H11.5829L10.3714 18.8529L8.29155 17.9808L5.90947 18.6362L5.34203 18.0688L6.00385 15.693L5.14482 13.6101L3 12.3876V11.583L5.1471 10.3715L6.0192 8.29155L5.36375 5.90947L5.93001 5.34321L8.30576 6.00595L10.3895 5.14655L11.6093 3H12.4129L13.6245 5.1471L15.7044 6.0192L18.087 5.36362L18.6558 5.93166L17.9941 8.30696L18.8534 10.3906L21 11.6103V12.4139L18.8527 13.6256ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#f68220" />
            </svg>
          </button>
        </div>
      </div>
      <div className='h-screen bg-custom-fcfff7 relative overflow-y-auto overflow-x-hidden no-scrollbar w-full pt-12'>
        <div className="flex items-center justify-center my-5 mx-5">
          <input
            type="text"
            placeholder="Search"
            onFocus={() => { navigate('/app/discover/search') }}
            className="h-12 border border-gray-300 rounded-xl px-5 w-full max-w-md text-black "
          />
        </div>
        <div id='' className="flex overflow-x-auto py-5 px-5 space-x-4 no-scrollbar"
          onMouseEnter={() => { setIsInteractingWithMenu(true); }}
          onMouseLeave={() => { setIsInteractingWithMenu(false); }}
          onTouchStart={() => { setIsInteractingWithMenu(true); }}
          onTouchEnd={() => { setIsInteractingWithMenu(false); }}
        >
          <button onClick={() => {navigate('/app/beAMentor')}}>
            <div className="relative h-16 w-32 bg-custom-aed5ff flex-none rounded-lg">
              <img src={`/icons/generalIcons/beAMentor.svg`} className='absolute bottom-0 right-3' alt="ElipseIcon" />
            </div>
            <p className='text-center font-semibold text-tertiary text-base pt-2'>Be a Mentor</p>
          </button>
          < button onClick={() => {navigate('/app/startupProgram')}}>
            <div className="relative h-16 w-32 bg-custom-aed5ff flex-none rounded-lg">
              <img src={`/icons/generalIcons/startupIcon.svg`} className='absolute -bottom-2 right-0' alt="ElipseIcon" />
            </div>
            <p className='text-center font-semibold text-tertiary text-base pt-2'>Startup Program</p>
          </button>
          {/* < button>
            <div className="relative h-16 w-32 bg-custom-aed5ff flex-none rounded-lg">
              <img src={`/icons/generalIcons/referBuddyIcon.svg`} className='absolute bottom-0 right-3' alt="ElipseIcon" />
            </div>
            <p className='text-center font-semibold text-tertiary text-base pt-2'>Refer a Buudy</p>
          </button> */}
          <button onClick={() => {navigate('/app/aidForAspiring')}}>
            <div className="relative h-16 w-32 bg-custom-aed5ff flex-none rounded-lg">
              <img src={`/icons/generalIcons/aidForAspiringIcon.svg`} className='absolute bottom-0 right-3' alt="ElipseIcon" />
            </div>
            <p className='text-center font-semibold text-tertiary text-base pt-2'>Aid for Aspiring</p>
          </button>
          {/* < button>
            <div className="relative h-16 w-32 bg-custom-aed5ff flex-none rounded-lg">
              <img src={`/icons/generalIcons/masterboardIcon.svg`} className='absolute bottom-0 right-3' alt="ElipseIcon" />
            </div>
            <p className='text-center font-semibold text-tertiary text-base pt-2'>Masterboard</p>
          </button>
          < button>
            <div className="relative h-16 w-32 bg-custom-aed5ff flex-none rounded-lg">
              <img src={`/icons/generalIcons/creativeCanvas.svg`} className='absolute h-20 bottom-0 right-3' alt="ElipseIcon" />
            </div>
            <p className='text-center font-semibold text-tertiary text-base pt-2'>Apply Jobs</p>
          </button> */}
        </div>
        {exploreLoading ? (
          <div className="grid grid-cols-3 justify-around gap-2 px-5 pt-4 items-center">
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
            <div role="status" className="w-full max-w-md animate-pulse">
              <div className="w-full aspect-square bg-gray-200 rounded-md dark:bg-gray-800" />
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-3 justify-around gap-2 px-5 pt-4 items-center mb-4">
            {explore.feed.length ? (
              explore.feed.map((post, index) => (
                <button key={index} className="w-full max-w-md aspect-square rounded-md" onClick={() => navigate(`feed/${post._id}`)}>
                  <img src={post.images[0].image_480.publicURL} alt='none' className='aspect-square object-cover rounded-md' />
                </button>
              ))
            ) : (
              <p className="text-center text-lg text-tertiary col-span-3">No posts to display</p>
            )}
          </div>
        )
        }
      </div>
    </div>
  );
};

export default Discover;
