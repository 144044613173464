import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../api/axiosInstance';
import { setToken } from '../../utils/secureStorage';
import InputText from '../main/InputText';
import Button from '../main/Button';
import Snackbar from '../main/elements/Snackbar';

const ForgotPassword = () => {
    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({});
    const navigate = useNavigate();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError({ password: 'Passwords do not match' });
            setShowSnackbar(true);
            setSnackbarMessage(error.password);
            return;
        }

        setIsLoading(true);
        try {
            const response = await api.post('/login', { email, password });
            if (response.data.error) {
                setError({ general: response.data.message });
                setShowSnackbar(true);
                setSnackbarMessage(response.data.message);
            } else {
                setToken('accessToken', response.data.accessToken);
                setToken('refreshToken', response.data.refreshToken);
                navigate('/app');
            }
        } catch (err) {
            setError({ general: 'Login failed. Please try again.' });
            setShowSnackbar(true);
            setSnackbarMessage(error.general);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        setError({ ...error, general: '', email: '' }); // Clear email error when typing
    };

    const handlePasswordChange = (value) => {
        setPassword(value);
        setError({ ...error, password: '', general: '' }); // Clear password and general errors when typing
    };

    const handleConfirmPasswordChange = (value) => {
        setConfirmPassword(value);
        setError({ ...error, password: '', general: '' }); // Clear password and general errors when typing
    };

    return (
        <div className='h-screen relative overflow-hidden w-full'>
            {showSnackbar && (
                <Snackbar
                message={snackbarMessage}
                onDismiss={() => setShowSnackbar(false)}
                />
            )}
            <div className="flex flex-col items-center justify-center bg-primary">
                <div className="relative">
                    <h1 className="absolute text-white text-center text-3xl font-bold" style={{ left: '50%', top: '35%', transform: 'translate(-50%, -50%)' }}>Engage</h1>
                    <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/elipse.svg`} alt="ElipseIcon" />
                </div>
                <div className="absolute bottom-0 w-full bg-white p-5 px-3 rounded-t-2xl">
                    <div>
                        <h2 className="text-2xl text-center font-semibold font-poppins text-primary">Forgot Password</h2>
                        <p className="text-center text-sm font-lato text-gray-500 mt-1">Enter your Email and new password</p>
                    </div>

                    <div className="flex-1 h-px my-3"></div>
                    <div className="space-y-4 relative">
                        <InputText
                            placeholder="Enter Email Address"
                            onChange={(e) => handleEmailChange(e.target.value)}
                            hasError={!!error.email}
                        />
                        <InputText
                            placeholder="Enter New Password"
                            type={showPassword ? "password" : "text"}
                            onChange={(e) => handlePasswordChange(e.target.value)}
                            hasError={!!error.password}>
                            <button onClick={() => setShowPassword(!showPassword)} className="absolute right-4 top-0 h-full flex items-center z-10">
                                <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/eye.svg`} alt="Eye Icon" />
                            </button>
                        </InputText>
                        <InputText
                            placeholder="Confirm New Password"
                            type={showConfirmPassword ? "password" : "text"}
                            onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                            hasError={!!error.password}>
                            <button onClick={() => setShowConfirmPassword(!showConfirmPassword)} className="absolute right-4 top-0 h-full flex items-center z-10">
                                <img src={`${process.env.PUBLIC_URL}/icons/authenticationIcons/eye.svg`} alt="Eye Icon" />
                            </button>
                        </InputText>
                        {error.password && <p className="text-red-500 text-sm">{error.password}</p>}
                        {error.general && <p className="text-red-500 text-sm">{error.general}</p>}
                    </div>
                    <div className="mt-5">
                        <Button text="Continue" isLoading={isLoading} onClick={handleSubmit} variant="primary" />
                    </div>
                    <Link to={"/login"} className='mt-5 flex text-sm justify-center gap-x-1'>
                        <p className='text-gray-700 text-sm'>Go back to</p>
                        <p className="text-primary font-bold" >Login</p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
