import React, { useEffect, useState } from 'react';
import InputText from '../InputText';
import Button from '../Button';
import api from '../../../api/axiosInstance';
import Snackbar from './Snackbar';
import { useNavigate } from 'react-router-dom';

const OTPModal = ({ onClose }) => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Add transition class to slide up
    const modal = document.querySelector('.otp-modal');
    if (modal) {
      setTimeout(() => {
        modal.classList.remove('translate-y-full');
        modal.classList.add('translate-y-0');
      }, 10);
    }
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.patch('/login/email/otp', { otp });
      if (response.data.error) {
          setError(response.data.message);
          setIsLoading(false);
          setShowSnackbar(true);
          setSnackbarMessage(response.data.message);
          return;
      } else {
          console.log(response.data.message);
          navigate('/app/settings');
      }
    } catch (err) {
        setIsLoading(false);
        setShowSnackbar(true);
        setSnackbarMessage("Error occurred while changing email");
    } finally {
        setIsLoading(false);
    }
  }

  return (
    <div className="fixed inset-0 flex items-end justify-center bg-gray-800 bg-opacity-50 z-50">
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          onDismiss={() => setShowSnackbar(false)}
        />
      )}
      <div className="w-full max-w-md p-6 pb-10 bg-white rounded-t-xl shadow-lg transform transition-transform duration-300 ease-out otp-modal translate-y-full">
        <div className="relative flex justify-center mb-2">
          <h2 className="text-2xl font-semibold text-center text-primary">Enter OTP</h2>
          <button onClick={onClose} className="absolute font-medium text-custom-ea1111 right-1">
            Cancel
          </button>
        </div>
        <p className="w-full px-10 mb-5 text-center text-custom-959090">
          Please enter the 6-digit OTP sent to your email.
        </p>
        <form autocomplete="off" onSubmit={submit}>
          <div className="mb-10">
            <InputText placeholder={"6 digit OTP"} onChange={(e) => setOtp(e.target.value)} hasError={error.otp} required/>
          </div>
          <Button variant={"primary"} text={"Continue"} isLoading={isLoading} type={"submit"} />
        </form>
      </div>
    </div>
  );
};

export default OTPModal;
