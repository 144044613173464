import React, { useState } from 'react';
import Header from '../../main/Header';

const Rewards = ({ cb }) => {

    const [data, setData] = useState([{"url":"https://www.instagram.com/p/C8uU45AOZqd/?utm_source=ig_web_copy_link", "status":"Pending", "description":"In 2018, Jonah hill left the a reunion with the cast of his film, ‘Mid90s’ to attend to Kanye’s album party for ‘Ye’ in Wyoming."},
        {"url":"https://www.instagram.com/p/C8uU45AOZqd/?utm_source=ig_web_copy_link", "status":"Pending", "description":"In 2018, Jonah hill left the a reunion with the cast of his film, ‘Mid90s’ to attend to Kanye’s album party for ‘Ye’ in Wyoming."},{"url":"https://www.instagram.com/p/C8uU45AOZqd/?utm_source=ig_web_copy_link", "status":"Pending", "description":"In 2018, Jonah hill left the a reunion with the cast of his film, ‘Mid90s’ to attend to Kanye’s album party for ‘Ye’ in Wyoming."},{"url":"https://www.instagram.com/p/C8uU45AOZqd/?utm_source=ig_web_copy_link", "status":"Pending", "description":"In 2018, Jonah hill left the a reunion with the cast of his film, ‘Mid90s’ to attend to Kanye’s album party for ‘Ye’ in Wyoming."},
        {"url":"https://www.instagram.com/p/C8uU45AOZqd/?utm_source=ig_web_copy_link", "status":"Pending", "description":"In 2018, Jonah hill left the a reunion with the cast of his film, ‘Mid90s’ to attend to Kanye’s album party for ‘Ye’ in Wyoming."}]);

    return(
        <div className="h-full bg-custom-fcfff7">
            <Header isBack={true} text={"Rewards"}/>
            <div className="grid grid-cols-2 place-items-center mt-4 space-y-2 px-2">
            {data?.map((cb, index) => (
                <div className="relative w-44 bg-fcfff7 border border-custom-d9d9d9 rounded-2xl shadow-md overflow-hidden p-1" key={index}>
                    <img className="h-20 w-full object-cover rounded-xl" src={"https://images.zingoy.com/image/upload/f_auto,c_scale,fl_lossy,q_auto,h_132,w_auto/f_auto,q_auto/v1699301742/linkisin/production/store/flipkart_kthadq.png"} alt='' />
                    <div className="w-full flex justify-center items-center mt-2.5">
                        <p className="font-lato text-sm p-1 text-center font-semibold truncate">Flipkart voucher</p>
                    </div>
                    <button className="w-full bg-primary rounded-full mt-2 flex justify-center items-center">
                        <p className="text-white font-lato font-bold py-px px-3">Redeem</p>
                    </button>
                </div>
            ))}
            {data?.length === 0 && (
                <div className="w-full">
                    <p className="text-center">No reward available</p>
                </div>
            )}
        </div>
        </div>
    )
}
export default Rewards;