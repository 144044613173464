import React from 'react';

export default function InputText({ hasError, placeholder, type = 'text', onChange, children, extraStyles, required, ...props }) {
    return (
        <div className="relative w-full">
            {children}
            <input
                className={`border w-full h-10 focus:ring-primary focus:border-primary rounded-full relative text-black pl-5 pr-9 
                    ${hasError ? 'border-red-500' : 'border-gray-300'} 
                    ${extraStyles}`}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                required={required}
                {...props}
            />
        </div>
    );
}
