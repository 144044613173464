import React, { useState } from 'react';
import InputText from '../../main/InputText';
import Button from '../../main/Button';
import Header from '../../main/Header';
import api from '../../../api/axiosInstance';
import Snackbar from "../../main/elements/Snackbar";
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);
  const [showPassword3, setShowPassword3] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const newErrors = {};
      const trimmedCurrentPassword = currentPassword.trim();
      const trimmedNewPassword = newPassword.trim();
      const trimmedConfirmPassword = confirmPassword.trim();

      if (trimmedNewPassword.length < 8) {
        newErrors.newPassword = "Invalid new password";
        newErrors.message = "Invalid new password";
      }
      if (trimmedNewPassword !== trimmedConfirmPassword) {
        newErrors.confirmPassword = "Invalid confirm password";
        newErrors.message = "Invalid confirm password";
      }
      if (trimmedCurrentPassword === trimmedNewPassword) {
        newErrors.newPassword = "Same old and new passwords";
        newErrors.message = "Same old and new passwords";
      }

      if (Object.keys(newErrors).length > 0) {
        setError(newErrors);
        setIsLoading(false);
        setShowSnackbar(true);
        setSnackbarMessage(error.message)
        return;
      } else {
        const response = await api.patch('/login/password', { currentPassword:trimmedCurrentPassword, newPassword:trimmedNewPassword, confirmPassword:trimmedConfirmPassword });
        if (response.data.error) {
          setError(response.data.message);
          setShowSnackbar(true);
          setSnackbarMessage(response.data.message);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log(response.data.message);
          navigate('/app/settings');
        }
      }

    } catch (error) {
      setIsLoading(false);
      console.error(error.message);
    }
  };

  return (
    <div className="h-screen bg-custom-fcfff7">
      <Header isBack={true} text={"Change Password"} />
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          onDismiss={() => setShowSnackbar(false)}
        />
      )}
      <form onSubmit={submit}>
        <div className="flex flex-col gap-4 p-6">
          <InputText placeholder="Enter Current Password" onChange={(e) => setCurrentPassword(e.target.value)} hasError={error.currentPassword} type={showPassword ? "password" : "text"} required={true}>
            <button onClick={(e) => { e.preventDefault(); setShowPassword(!showPassword); }} className="absolute right-4 top-0 h-full flex items-center z-10">
              <img src={`/icons/authenticationIcons/eye.svg`} alt="Eye Icon" />
            </button>
          </InputText>
          <InputText placeholder="Enter New Password" onChange={(e) => setNewPassword(e.target.value)} hasError={error.newPassword} type={showPassword2 ? "password" : "text"} required={true}>
            <button onClick={(e) => { e.preventDefault(); setShowPassword2(!showPassword2); }} className="absolute right-4 top-0 h-full flex items-center z-10">
              <img src={`/icons/authenticationIcons/eye.svg`} alt="Eye Icon" />
            </button>
          </InputText>
          <InputText placeholder="Confirm New Password" onChange={(e) => setConfirmPassword(e.target.value)} hasError={error.confirmPassword} type={showPassword3 ? "password" : "text"} required={true}>
            <button onClick={(e) => { e.preventDefault(); setShowPassword3(!showPassword3); }} className="absolute right-4 top-0 h-full flex items-center z-10">
              <img src={`/icons/authenticationIcons/eye.svg`} alt="Eye Icon" />
            </button>
          </InputText>
        </div>
        <div className="flex justify-center w-full max-w-md p-5 absolute bottom-2">
          <Button text="Change Password" variant="primary" isLoading={isLoading} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
