import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      Go to <NavLink to="/app" className="font-bold">Home</NavLink>
    </div>
  );
};

export default NotFound;
