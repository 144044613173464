import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../main/Header';
import PostCard from '../../main/elements/PostCard';
import ProfileCard from '../../main/elements/ProfileCard';
import api from '../../../api/axiosInstance';

const Search = () => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [posts, setPosts] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            if (searchText.trim() !== '') {
                try {
                    let q = searchText;
                    const result = await api.post('/search/advanced', { q });
                    if (!result.data.error) {
                        setPosts(result.data.posts ?? []);
                        setProfiles(result.data.profiles ?? []);
                    }
                } catch (error) {
                    console.error("Error fetching search results:", error);
                }
            } else {
                setPosts([]);
                setProfiles([]);
            }
        };
        fetchData();
    }, [searchText]);

    const handleProfileClick = (id) => {
        navigate(`/app/discover/viewProfile/${id}`);
    };

    return (
        <div className="min-h-screen bg-custom-fcfff7">
            <div className='sticky top-0 bg-custom-fcfff7'>
                <Header isBack={true} text={"Search"} />
                <div className="flex items-center justify-center py-5 px-5">
                    <input
                        type="text"
                        placeholder="Search"
                        className="h-12 border border-gray-300 rounded-xl px-5 w-full max-w-md text-black"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        autoFocus={true}
                    />
                </div>
                <div className="flex overflow-x-auto gap-4 px-5 py-2 shadow-sm">
                    <button
                        onClick={() => setTab(0)}
                        className={`px-4 py-1 pt-2 text-bold flex items-center justify-center rounded-full ${tab === 0 ? 'bg-orange-500 text-white' : 'bg-orange-200'}`}
                    >
                        All
                    </button>
                    <button
                        onClick={() => setTab(1)}
                        className={`px-4 py-1 flex items-center justify-center rounded-full ${tab === 1 ? 'bg-orange-500 text-white' : 'bg-orange-200'}`}
                    >
                        Post
                    </button>
                    <button
                        onClick={() => setTab(2)}
                        className={`px-4 py-1 flex items-center justify-center rounded-full ${tab === 2 ? 'bg-orange-500 text-white' : 'bg-orange-200'}`}
                    >
                        Profile
                    </button>
                </div>
            </div>
            <div className="pt-4 px-5 flex flex-col gap-4">
                {((tab === 0) || (tab === 1)) && posts.length > 0 && (
                    <>
                        <div className="font-medium text-sm">Posts</div>
                        <div className="flex flex-wrap gap-4">
                            {posts.map((post, index) => (
                                <div key={index} className="w-full md:w-1/2">
                                    <button onClick={() => {navigate(`/app/feed/${post._id}`)}} key={index} className="w-full md:w-1/2">
                                        <PostCard data={post} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {((tab === 0) || (tab === 2)) && profiles.length > 0 && (
                    <>
                        <div className="font-semibold text-sm">Profiles</div>
                        <div className="flex flex-wrap gap-1">
                            {profiles.map((profile, index) => (
                                <button onClick={() => handleProfileClick(profile?.of)} key={index} className="w-full md:w-1/2">
                                    <ProfileCard data={profile} />
                                </button>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Search;
